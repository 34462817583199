import React, { useState } from "react";
import EnterpriseGridCommon from "./Ag-Grid";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useTranslation } from "react-i18next";
import ExclamationIcon from "../../assets/exclamation.svg";
import { logDataHelperFunctions } from "../../helpers/logdata-helper";
import { passViewHelper } from "../../helpers/passzone/pass-zone-helper";
import { aggridHelpers } from "../../helpers/common/ag-grid-helper";

const PassViewAgGrid = ({ rows, isLoading, reportColumns, handleRowClick }) => {
  const { t } = useTranslation();

  const valueRender = (params) => {
    const text = params.value;
    if (text?.includes("-color")) {
      return <span className="red-note">{text?.replace("-color", "")}</span>;
    }
    return isNaN(text) ? "" : text;
  };

  const [columnDefs] = useState([
    {
      field: "sl_no",
      headerName: t("slogColumntitle.sl.no"),
      key: "sl_no",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: false,
    },
    {
      field: "status",
      headerName: t("slogColumntitle.status"),
      key: "status",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: false,
      cellRenderer: (params) => {
        return params.value
          ? logDataHelperFunctions.getIconForStatus(params.value)
          : "";
      },
    },
    {
      field: "stationNumbers",
      headerName: t("slogColumntitle.stationName"),
      key: "stationNumbers",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: true,
    },
    {
      field: "buyType",
      headerName: t("slogColumntitle.buyType"),
      key: "buyType",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: true,
    },
    {
      field: "type",
      headerName: t("slogColumntitle.torch"),
      editable: true,
      key: "type",
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: true,
    },
    {
      field: "name",
      headerName: t("slogColumntitle.pass"),
      key: "pass",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: true,
      cellRenderer: (params) => {
        return params.value == "!" ? (
          <img src={ExclamationIcon} alt="Exclamation" />
        ) : (
          params.value
        );
      },
    },
    {
      field: "rawWeldStartTime",
      headerName: t("slogColumntitle.weldstartTime"),
      key: "weldStartTime",
      editable: true,
      pivot: true,
      cellRenderer: (params) => {
        return Number(params.value) > 0
          ? logDataHelperFunctions.formatWeldStartTime(params.value, true)
          : "-";
      },
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: false,
      filter: "agDateColumnFilter",
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          return aggridHelpers.dateFilter(filterLocalDateAtMidnight, cellValue);
        },
        maxNumConditions: 1,
        inRangeInclusive: true,
      },
      minWidth: 150,
    },
    {
      field: "weld_time",
      headerName: t("slogColumntitle.weldTime"),
      key: "weld_time",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: false,
    },
    {
      field: "autoIncrement",
      headerName: t("slogColumntitle.autoIncrement"),
      key: "autoIncrement",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: false,
      minWidth: 150,
    },
    {
      headerName: t("slogColumntitle.current"),
      key: "currentA",
      children: [
        {
          field: "amps.low",
          headerName: t("slogColumntitle.low"),
          key: "amps_low",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          field: "amps.avg",
          headerName: t("slogColumntitle.avg"),
          editable: true,
          key: "amps_avg",
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          field: "amps.high",
          headerName: t("slogColumntitle.high"),
          key: "amps_high",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
      ],
    },
    {
      headerName: t("slogColumntitle.volt"),
      key: "voltV",
      children: [
        {
          field: "volts.low",
          headerName: t("slogColumntitle.low"),
          key: "volts_low",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          field: "volts.avg",
          headerName: t("slogColumntitle.avg"),
          key: "volts_avg",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          field: "volts.high",
          headerName: t("slogColumntitle.high"),
          key: "volts_high",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
      ],
    },
    {
      headerName: t("slogColumntitle.travelSpeed"),
      key: "travelSpeed",
      children: [
        {
          field: "travel.low",
          headerName: t("slogColumntitle.low"),
          key: "travel_low",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          field: "travel.avg",
          headerName: t("slogColumntitle.avg"),
          key: "travel_avg",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          field: "travel.high",
          headerName: t("slogColumntitle.high"),
          key: "travel_high",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
      ],
    },
    {
      headerName: t("slogColumntitle.trueEnergy"),
      key: "trueEnergy",
      children: [
        {
          field: "heat.low",
          headerName: t("slogColumntitle.low"),
          key: "heat_low",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          field: "heat.avg",
          headerName: t("slogColumntitle.avg"),
          key: "heat_avg",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          field: "heat.high",
          headerName: t("slogColumntitle.high"),
          key: "heat_high",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
      ],
    },
    {
      headerName: t("slogColumntitle.heat"),
      key: "heat",
      children: [
        {
          field: "calculated.low",
          headerName: t("slogColumntitle.low"),
          key: "calculated_low",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          field: "calculated.avg",
          headerName: t("slogColumntitle.avg"),
          key: "calculated_avg",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          field: "calculated.high",
          headerName: t("slogColumntitle.high"),
          key: "calculated_high",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
      ],
    },
    {
      headerName: t("slogColumntitle.wireSpeed"),
      key: "wireSpeed",
      children: [
        {
          field: "wire.low",
          headerName: t("slogColumntitle.low"),
          key: "wire_low",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          field: "wire.avg",
          headerName: t("slogColumntitle.avg"),
          key: "wire_avg",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          field: "wire.high",
          headerName: t("slogColumntitle.high"),
          key: "wire_high",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
      ],
    },
    {
      headerName: t("slogColumntitle.oscillationWidth"),
      key: "oscillationWidth",
      children: [
        {
          headerName: t("slogColumntitle.low"),
          key: "oscillate_low",
          field: "oscillate.low",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.avg"),
          key: "oscillate_avg",
          field: "oscillate.avg",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.high"),
          dataIndex: ["oscillate", "high"],
          key: "oscillate_high",
          field: "oscillate.high",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
      ],
    },
    {
      headerName: t("slogColumntitle.frequency"),
      key: "frequency",
      children: [
        {
          headerName: t("slogColumntitle.low"),
          key: "frequency_low",
          field: "frequency.low",
          enableRowGroup: false,
        },
        {
          headerName: t("slogColumntitle.avg"),
          key: "frequency_avg",
          field: "frequency.avg",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.high"),
          key: "frequency_high",
          field: "frequency.high",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
      ],
    },
    {
      headerName: t("slogColumntitle.target"),
      key: "target",
      children: [
        {
          headerName: t("slogColumntitle.low"),
          key: "target_low",
          field: "target.low",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.avg"),
          key: "target_avg",
          field: "target.avg",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.high"),
          key: "target_high",
          field: "target.high",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
      ],
    },
    {
      headerName: t("slogColumntitle.horizontalBias"),
      key: "horizontalBias",
      position: 18,
      children: [
        {
          headerName: t("slogColumntitle.low"),
          key: "horizontal_low",
          field: "horizontal.low",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.avg"),
          key: "horizontal_avg",
          field: "horizontal.avg",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.high"),
          key: "horizontal_high",
          field: "horizontal.high",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
      ],
    },
    {
      headerName: t("slogColumntitle.totalWireConsumed"),
      key: "totalWireConsumed",
      position: 19,
      children: [
        {
          headerName: t("slogColumntitle.low"),
          key: "total_low",
          field: "total.low",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.avg"),
          key: "total_avg",
          field: "total.avg",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.high"),
          key: "total_high",
          field: "total.high",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
      ],
    },
    {
      headerName: t("slogColumntitle.arcImpedance"),
      key: "arcImpedance",
      children: [
        {
          field: "arcImpedance.low",
          headerName: t("slogColumntitle.low"),
          key: "arcImpedance_low",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          field: "arcImpedance.avg",
          headerName: t("slogColumntitle.avg"),
          key: "arcImpedance_avg",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          field: "arcImpedance.high",
          headerName: t("slogColumntitle.high"),
          key: "arcImpedance_high",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
      ],
    },
  ]);

  const onRowClicked = (params) => {
    console.log("Row clicked", params);
    handleRowClick(params.data);
  };

  return (
    <div className="ag-theme-alpine" style={{ height: 500, width: "100%" }}>
      <EnterpriseGridCommon
        isLoading={isLoading}
        rows={rows}
        columnDefs={passViewHelper.mapPassViewColumns(
          columnDefs,
          reportColumns
        )}
        onRowClicked={onRowClicked}
        exportFileName={"pass-view"}
      />
    </div>
  );
};

export default PassViewAgGrid;
