const displayFields = [
  {
    name: "tlogData.bugno",
    value: "customer_name",
  },
  {
    name: "tlogData.gps",
    value: "user_id",
  },
  {
    name: "tlogData.wirediameter",
    value: "welding_system",
  },
  {
    name: "tlogData.loggingdistance",
    value: "iwm",
  },
  {
    name: "tlogData.pipediameter",
    value: "start_date",
  },
  {
    name: "tlogData.jobno",
    value: "end_date",
  },
  {
    name: "tlogData.weldstarttime",
    value: "welds_completed",
  },
  {
    name: "tlogData.weldendtime",
    value: "pipe_thickness",
  },
  {
    name: "tlogData.weldtime",
    value: "pipe_diameter",
  },
];

export { displayFields };
