import { AgGridReact } from "ag-grid-react";
import React, { useState, useEffect, useMemo } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../global-styles/AGGrid.scss";
import "../../global-styles/AGGrid-light-theme.scss";
import {
  ClientSideRowModelModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  LicenseManager,
  MenuModule,
  PaginationModule,
  RowGroupingModule,
  SetFilterModule,
  StatusBarModule,
  ExcelExportModule,
  CsvExportModule,
  DateFilterModule,
} from "ag-grid-enterprise";

// Set AG Grid Enterprise License Key
LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_ENTERPRISE_LICENSE);
const EnterpriseGridCommon = ({
  rows,
  columnDefs,
  isLoading,
  onRowClicked,
  exportFileName,
}) => {
  const filteredRows = useMemo(() => {
    return rows?.filter((row) => row && Object.keys(row).length > 0) || [];
  }, [rows]);

  const [rowData, setRowData] = useState(filteredRows);

  useEffect(() => {
    setRowData(filteredRows);
  }, [filteredRows]);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            // Hide the Values section
            suppressPivotMode: true,
            suppressValues: true,
            suppressRowGroups: false,
            suppressPivots: true
          }
        },
        'filters'
      ]
    };
  }, []);

  const defaultCsvExportParams = useMemo(() => {
    return {
      fileName: `${exportFileName}.csv`, // Default file name
      allColumns: true, // Include all columns by default
      onlySelected: false, // Include all rows, not just selected ones
    };
  }, []);

  const defaultExcelExportParams = useMemo(() => {
    return {
      fileName: `${exportFileName}.xlsx`, // Default file name
      allColumns: true, // Include all columns by default
      onlySelected: false, // Include all rows, not just selected ones
      sheetName: "Sheet1", // Default sheet name
    };
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 50,
      pinned: "left",
      suppressAutoSize: true,
      cellClassRules: {
        "total-group-row": (params) => params.node.footer, // Apply class for total rows
      },
      cellRendererParams: {
        suppressCount: true,
      },
    };
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 120,
      resizable: true,
      enableValue: true,
      enableRowGroup: true,
      enablePivot: true,
      filter: true,
      wrapHeaderText: true,
      suppressAutoSize: true,
      filterParams: {
        comparator: (a, b) => a.localeCompare(b),
      },
      cellClassRules: {
        "pivot-total-value": (params) => params.node.footer, // Apply class only to footer rows
      },
    };
  }, []);

  const gridOptions = {
    domLayout: "autoHeight",
    enableRangeSelection: true, // Enable selection of ranges (for export purposes)
    suppressExcelExport: false, // Make sure to allow export
  };

  return (
    <div className="ag-theme-alpine" style={{ height: 500, width: "100%" }}>
      <AgGridReact
        modules={[
          ClientSideRowModelModule,
          ColumnsToolPanelModule,
          FiltersToolPanelModule,
          MenuModule,
          SetFilterModule,
          StatusBarModule,
          RowGroupingModule,
          PaginationModule,
          ExcelExportModule,
          CsvExportModule,
          DateFilterModule,
        ]}
        gridOptions={gridOptions}
        defaultColDef={defaultColDef}
        autoGroupColumnDef={autoGroupColumnDef}
        columnDefs={columnDefs}
        rowData={rowData}
        defaultExcelExportParams={defaultExcelExportParams}
        defaultCsvExportParams={defaultCsvExportParams}
        pivotPanelShow={"always"}
        rowDragManaged={true}
        sideBar={sideBar}
        rowSelection="multiple"
        animateRows={true}
        enableFilter={true}
        groupDefaultExpanded={2}
        pivotDefaultExpanded={2}
        groupTotalRow={null}
        grandTotalRow={null}
        groupDisplayType={"multipleColumns"}
        showOpenedGroup={true}
        suppressGroupRowsSticky={true}
        groupHideOpenParents={true}
        rowHeight={40}
        headerHeight={40}
        enableExcelExport={true}
        suppressMenuHide={true} // Prevents hiding the menu
        enableStrictPivotColumnOrder={true}
        removePivotHeaderRowWhenSingleValueColumn={true}
        loadingOverlayComponentParams={{
          loadingMessage: "Loading all rows...",
        }}
        onRowClicked={onRowClicked}
        loading={isLoading}
        pagination={true}
        paginationPageSize={50}
        paginationPageSizeSelector={[30, 50, 100]}
        domLayout="normal"
      />
    </div>
  );
};

export default EnterpriseGridCommon;
