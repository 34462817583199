import { Tabs } from "antd";
import React from "react";
import PassZone from "./PassZone";
import TiltView from "./TiltView";
import ZoneView from "./ZoneView";

const SlogTab = ({ setActiveTab, weld_number }) => {
  const tabKeys = {
    PassView: "PassView",
    ZoneView: "ZoneView",
    TiltView: "TiltView",
  };

  const tabItems = [
    {
      key: tabKeys.PassView,
      label: "Pass View",
      children: <PassZone weld_number={weld_number} />,
    },
    {
      key: tabKeys.ZoneView,
      label: "Zone View",
      children: <ZoneView weld_number={weld_number} />,
    },
    {
      key: tabKeys.TiltView,
      label: "Tilt View",
      children: <TiltView weld_number={weld_number} />,
    },
  ];

  const handleTabChange = (key) => {
    if (key === tabKeys.PassView) {
      setActiveTab("1");
    } else if (key === tabKeys.ZoneView) {
      setActiveTab("2");
    } else if (key === tabKeys.TiltView) {
      setActiveTab("3");
    }
  };

  return (
    <Tabs
      defaultActiveKey={tabKeys.PassView}
      items={tabItems}
      className="custom-tab"
      destroyInactiveTabPane={true}
      onChange={handleTabChange}
    />
  );
};

export default SlogTab;
