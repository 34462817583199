import { tlogHelper } from "./tlog-helper";
import { logDataHelperFunctions } from "../logdata-helper";

const getPassName = (tlog, stationNumber, type, tableLimit) => {
  let tableLimitFiltered = tableLimit?.filter(
    (ite) => ite.job_number == tlog.job_number
  );
  let passName =
    stationNumber == "0"
      ? "Root IWM"
      : type == "Lead" || stationNumber == "0"
      ? tlog.lead_pass_name_actual
      : tlog.trail_pass_name_actual;

  return passName ? passName : tableLimitFiltered.length == 0 ? "-" : "!";
};

const getZoneName = (tlog, stationNumber, type) => {
  let zoneName =
    type == "Lead" || stationNumber == "0"
      ? tlog.lead_pass_name
      : tlog.trail_pass_name;

  return zoneName ? zoneName : "!";
};

const getValuesForColumns = ({
  leadKeyName,
  trailKeyName,
  column,
  type,
  stationNumber,
  tableLimitData,
  tlog,
  isOnlyLeadFilter,
  currentColumnProjectParam,
  weldParamsShowColumns,
  boundary,
}) => {
  const getFilterResult = (keyName) => {
    return (
      tlog[keyName] +
      getMaxMinInRangeFilter({
        avgTlogItem: tlog,
        column: column,
        type: type,
        currentValue: tlog[keyName],
        tableLimitData: tableLimitData,
        currentColumnProjectParam: currentColumnProjectParam,
        weldParamsShowColumns,
        boundary,
      })?.showColor
    );
  };

  if (isOnlyLeadFilter) {
    return type === "Lead"
      ? getFilterResult(leadKeyName)
      : getFilterResult(trailKeyName);
  }

  return type === "Lead" || stationNumber === "0"
    ? getFilterResult(leadKeyName)
    : getFilterResult(trailKeyName);
};

const getMaxMinInRangeFilter = ({
  avgTlogItem,
  tableLimitData,
  currentValue,
  type,
  column,
  currentColumnProjectParam,
  weldParamsShowColumns,
  boundary,
}) => {
  if (!avgTlogItem?.trail_pass_name && !avgTlogItem?.lead_pass_name) {
    return {
      doesExist: false,
      showColor: "",
    };
  }

  //in case of pqr dont show red circle
  if (boundary) {
    return {
      doesExist: false,
      showColor: "",
    };
  }

  let passNameExtracted = "";
  if (type == "Lead") {
    passNameExtracted = avgTlogItem?.lead_pass_name;
  } else if (type == "Trail") {
    passNameExtracted = avgTlogItem?.trail_pass_name;
  } else {
    passNameExtracted =
      avgTlogItem?.trail_pass_name || avgTlogItem?.lead_pass_name;
  }

  if (passNameExtracted.includes("Head")) {
    passNameExtracted = "HEAD";
  }

  let job_number = avgTlogItem?.job_number;

  let allTableLimitZones = [];

  if (job_number) {
    allTableLimitZones = tableLimitData?.filter(
      (tableLimit) => tableLimit.job_number == job_number
    );

    let enabled = logDataHelperFunctions.isColumnEnabledForRedCircle({
      currentColumn: currentColumnProjectParam,
      jobNumber: job_number,
      weldParamsShowColumns: weldParamsShowColumns,
    });

    if (!enabled) {
      return {
        doesExist: false,
        showColor: "",
      };
    }
  }

  allTableLimitZones = allTableLimitZones?.filter(
    (tableLimit) => tableLimit.zone_name == passNameExtracted
  );

  let allPassNames = allTableLimitZones?.map((ite) => ite.pass_name);

  let uniquePassNames = [...new Set([...allPassNames])];

  if (uniquePassNames?.length == 0) {
    return {
      doesExist: false,
      showColor: "",
    };
  } else {
    return {
      doesExist: true,
      showColor:
        allTableLimitZones?.[0]?.[`${column}_min`] &&
        allTableLimitZones?.[0]?.[`${column}_max`]
          ? parseFloat(currentValue) <
              parseFloat(allTableLimitZones?.[0]?.[`${column}_min`]) ||
            parseFloat(currentValue) >
              parseFloat(allTableLimitZones?.[0]?.[`${column}_max`])
            ? "-color"
            : ""
          : "",
    };
  }
};

export const tlogMapData = ({
  tlogRecords,
  station_number,
  tableLimitData,
  type,
  isFromPQRTab,
  reportColumns,
  weldParamsShowColumns,
  boundary,
  tilt_start = 0,
  tilt_end = 0,
}) => {
  let stationNumber = station_number;

  let tlogParsedData = tlogRecords
    ?.filter((ite) => {
      if (type == "Lead") {
        const isLeadDataEmpty =
          (ite.lead_volts == "" || ite.lead_volts == 0) &&
          (ite.lead_amps == "" || ite.lead_amps == 0) &&
          (ite.lead_wire_speed == "" || ite.lead_wire_speed == 0) &&
          (ite.lead_oscillate_width == "" || ite.lead_oscillate_width == 0) &&
          (ite.lead_target == "" || ite.lead_target == 0) &&
          (ite.lead_horizontal_bias == "" || ite.lead_horizontal_bias == 0) &&
          (ite.lead_frequency == "" || ite.lead_frequency == 0) &&
          (ite.lead_total_wire_consumed == "" ||
            ite.lead_total_wire_consumed == 0) &&
          (ite.lead_heat == "" || ite.lead_heat == 0) &&
          (ite.lead_calculated_heat == "" || ite.lead_calculated_heat == 0);

        return !isLeadDataEmpty;
      } else if (type == "Trail") {
        const isTrailDataEmpty =
          (ite.trail_volts == "" || ite.trail_volts == 0) &&
          (ite.trail_amps == "" || ite.trail_amps == 0) &&
          (ite.trail_wire_speed == "" || ite.trail_wire_speed == 0) &&
          (ite.trail_oscillate_width == "" || ite.trail_oscillate_width == 0) &&
          (ite.trail_target == "" || ite.trail_target == 0) &&
          (ite.trail_horizontal_bias == "" || ite.trail_horizontal_bias == 0) &&
          (ite.trail_frequency == "" || ite.trail_frequency == 0) &&
          (ite.trail_total_wire_consumed == "" ||
            ite.trail_total_wire_consumed == 0) &&
          (ite.trail_heat == "" || ite.trail_heat == 0) &&
          (ite.trail_calculated_heat == "" || ite.trail_calculated_heat == 0);

        return !isTrailDataEmpty;
      }
      return true;
    })
    .filter((ite) => {
      if (type == "Lead") {
        return ite.ignore_record != "true";
      } else if (type == "Trail") {
        return ite.ignore_record_trail != "true";
      } else {
        return ite.ignore_record != "true";
      }
    })
    .map((tlog, index) => {
      let headAngle = "";
      let arcImpedance = "";

      if (tlog.m500) {
        try {
          const m500Data = JSON.parse(tlog?.m500);
          headAngle = m500Data.Head_angle || "";
          arcImpedance = m500Data.Arc_impedance || "";
        } catch (error) {
          console.error("Error parsing m500 data:", error);
        }
      }
      // Get the zone object from tlog for M500 extraction
      const zoneObj = getZoneName(tlog, stationNumber, type);

      let isM500 = false;
      try {
        JSON.parse(zoneObj);
        isM500 = true;
      } catch (err) {
        isM500 = false;
      }

      // Extract M500 zone data
      const m500ZoneData = extractZoneAndPassForM500FromTableLimits(
        tableLimitData,
        tlog.job_number,
        zoneObj
      );

      return {
        sl_no: index + 1,
        status:
          type == "Lead" || type == ""
            ? logDataHelperFunctions.getIconForStatus(tlog.lead_status)
            : logDataHelperFunctions.getIconForStatus(tlog.trail_status),
        statusNew:
          type == "Lead" || type == "" ? tlog.lead_status : tlog.trail_status,
        event: tlog.deviceid_t_event?.split("_")[1],
        jobNumber: tlog.job_number,
        time: logDataHelperFunctions.formatWeldStartTime(tlog.time, "", true),
        tilt: tlog.tilt ? Number(tlog.tilt) : tlog.tilt,
        pipe: isM500 ? m500ZoneData.PipeNum : "-",
        pass: !isM500
          ? getPassName(tlog, stationNumber, type, tableLimitData)
          : m500ZoneData.PassNum,
        zone: !isM500
          ? getZoneName(tlog, stationNumber, type)
          : m500ZoneData.ZoneNum,
        distance: tlog.distance,
        travelSpeed: getValuesForColumns({
          column: "travel_speed",
          leadKeyName: "travel_speed",
          stationNumber: stationNumber,
          tableLimitData: tableLimitData,
          tlog: tlog,
          trailKeyName: "travel_speed",
          type,
          isOnlyLeadFilter: false,
          currentColumnProjectParam:
            logDataHelperFunctions.allParamsValues.travelSpeed,
          weldParamsShowColumns: weldParamsShowColumns,
          boundary,
        }),
        volts: getValuesForColumns({
          column: "volts",
          leadKeyName: "lead_volts",
          stationNumber: stationNumber,
          tableLimitData: tableLimitData,
          tlog: tlog,
          trailKeyName: "trail_volts",
          type,
          isOnlyLeadFilter: false,
          currentColumnProjectParam:
            logDataHelperFunctions.allParamsValues.arcVolts,
          weldParamsShowColumns: weldParamsShowColumns,
          boundary,
        }),
        current: getValuesForColumns({
          column: "current",
          leadKeyName: "lead_amps",
          trailKeyName: "trail_amps",
          stationNumber: stationNumber,
          tableLimitData: tableLimitData,
          tlog: tlog,
          type,
          isOnlyLeadFilter: false,
          currentColumnProjectParam:
            logDataHelperFunctions.allParamsValues.arcCurrent,
          weldParamsShowColumns: weldParamsShowColumns,
          boundary,
        }),
        wireSpeed: getValuesForColumns({
          column: "wire_speed",
          leadKeyName: "lead_wire_speed",
          trailKeyName: "trail_wire_speed",
          stationNumber: stationNumber,
          tableLimitData: tableLimitData,
          tlog: tlog,
          type,
          isOnlyLeadFilter: true,
          currentColumnProjectParam:
            logDataHelperFunctions.allParamsValues.wireSpeed,
          weldParamsShowColumns: weldParamsShowColumns,
          boundary,
        }),
        oscillationWidth: getValuesForColumns({
          column: "oscillation_width",
          leadKeyName: "lead_oscillate_width",
          trailKeyName: "trail_oscillate_width",
          stationNumber: stationNumber,
          tableLimitData: tableLimitData,
          tlog: tlog,
          type,
          isOnlyLeadFilter: true,
          currentColumnProjectParam:
            logDataHelperFunctions.allParamsValues.oscillationWidth,
          weldParamsShowColumns: weldParamsShowColumns,
          boundary,
        }),
        target: type == "Lead" ? tlog.lead_target : tlog.trail_target,
        horizontalBias:
          type == "Lead"
            ? tlog.lead_horizontal_bias
            : tlog.trail_horizontal_bias,
        frequency: type == "Lead" ? tlog.lead_frequency : tlog.trail_frequency,
        totalWireConsumed:
          type == "Lead"
            ? tlog.lead_total_wire_consumed
            : tlog.trail_total_wire_consumed,
        trueEnergy: getValuesForColumns({
          column: "heat",
          leadKeyName: "lead_heat",
          trailKeyName: "trail_heat",
          stationNumber: stationNumber,
          tableLimitData: tableLimitData,
          tlog: tlog,
          type,
          isOnlyLeadFilter: true,
          currentColumnProjectParam:
            logDataHelperFunctions.allParamsValues.heat,
          weldParamsShowColumns: weldParamsShowColumns,
          boundary,
        }),
        heat: getValuesForColumns({
          column: "calculated_heat",
          leadKeyName: "lead_calculated_heat",
          trailKeyName: "trail_calculated_heat",
          stationNumber: stationNumber,
          tableLimitData: tableLimitData,
          tlog: tlog,
          type,
          isOnlyLeadFilter: false,
          currentColumnProjectParam:
            logDataHelperFunctions.allParamsValues.calculatedHeat,
          weldParamsShowColumns: weldParamsShowColumns,
          boundary,
        }),
        // Add M500 zone data to the returned object
        headAngle: headAngle,
        arcImpedance: arcImpedance,
        m500Data: m500ZoneData,
      };
    });

  let isHavingHead = tlogParsedData.filter((ite) =>
    ite.zone.toLowerCase().includes("head")
  );

  if (isHavingHead?.length) {
    tlogParsedData = tlogParsedData
      .sort((a, b) => {
        if (a.zone < b.zone) return -1;
        if (a.zone > b.zone) return 1;
        return 0;
      })
      .map((ite, index) => {
        return {
          ...ite,
          sl_no: index + 1,
        };
      });
  }

  tlogParsedData = tlogParsedData?.map((ite) => {
    return isFromPQRTab
      ? ite
      : tlogHelper.mapTlogWithUnitConversion(ite, reportColumns);
  });

  if (tilt_start && tilt_end) {
    tlogParsedData = tlogParsedData?.filter((ite) => {
      const tilt = parseFloat(ite.tilt);
      if (tilt_start == "0" && tilt_end == "90") {
        return (tilt >= 0 && tilt <= 90) || (tilt >= 350 && tilt <= 360);
      } else if (tilt_start == "90" && tilt_end == "180") {
        return tilt > 90 && tilt < 270;
      } else if (tilt_start === "0" && tilt_end === "180") {
        return true;
      }
      return false;
    });
  }

  return tlogParsedData.map((ite, index) => {
    return {
      ...ite,
      sl_no: index + 1,
    };
  });
};

export const extractZoneAndPassForM500FromTableLimits = (
  tableLimits,
  jobNumber,
  zoneObj
) => {
  try {
    if (!Array.isArray(tableLimits) || !jobNumber || !zoneObj) {
      throw new Error("Invalid input parameters");
    }

    const record = tableLimits.find(
      (item) => item.job_number === jobNumber && item.zone_name === zoneObj
    );

    const newZoneObj = validateM500Zone(zoneObj);
    const extractedData = {
      PipeNum: record?.pipe_name ?? `Pipe ${newZoneObj?.PipeNum}`,
      PassNum: record?.pass_name ?? `Pass ${newZoneObj?.PassNum}`,
      ZoneNum: record?.m500_zone ?? `Zone ${newZoneObj?.ZoneNum}`,
    };

    return extractedData;
  } catch (error) {
    return { PipeNum: "-", PassNum: "-", ZoneNum: "-" };
  }
};

export const validateM500Zone = (obj) => {
  try {
    if (!obj) {
      throw new Error("Invalid input");
    }

    const parsedObj = typeof obj === "string" ? JSON.parse(obj) : obj;
    return {
      PipeNum: parsedObj?.PipeNum ?? "-",
      PassNum: parsedObj?.PassNum ?? "-",
      ZoneNum: parsedObj?.ZoneNum ?? "-",
    };
  } catch (error) {
    return { PipeNum: "-", PassNum: "-", ZoneNum: "-" };
  }
};
