import { AlignCenterOutlined, LineChartOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import React from "react";
import TLogGraph from "../components/TlogData/TlogGraph";
import TlogTableView from "../components/TlogData/TlogTableView";

const TlogTab = ({
  setActiveTab,
  type,
  weld_number,
  zone,
  s_deviceid_event,
  station_number,
  tlodVisibleColumns,
  tilt_start,
  tilt_end,
  isM500,
  rawZoneView,
}) => {
  const tabKeys = {
    TableView: "TableView",
    GraphView: "GraphView",
  };

  const tabItems = [
    {
      key: tabKeys.TableView,
      label: (
        <>
          <AlignCenterOutlined />
          Table View
        </>
      ),
      children: (
        <TlogTableView
          weld_number={weld_number}
          type={type}
          zone={zone}
          s_deviceid_event={s_deviceid_event}
          station_number={station_number}
          tlodVisibleColumns={tlodVisibleColumns}
          tilt_start={tilt_start}
          tilt_end={tilt_end}
          isM500={isM500}
          rawZoneView={rawZoneView}
        />
      ),
    },
    {
      key: tabKeys.GraphView,
      label: (
        <>
          <LineChartOutlined />
          Graph View
        </>
      ),
      children: (
        <TLogGraph
          weld_number={weld_number}
          type={type}
          zone={zone}
          s_deviceid_event={s_deviceid_event}
          station_number={station_number}
          tilt_start={tilt_start}
          tilt_end={tilt_end}
        />
      ),
    },
  ];

  return (
    <Tabs
      defaultActiveKey={tabKeys.TableView}
      items={tabItems}
      className="custom-tab"
      destroyInactiveTabPane={true}
      onChange={(key) => setActiveTab(key)}
    />
  );
};

export default TlogTab;
