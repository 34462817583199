import { LeftOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { Button, Drawer, List, Table } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import { commonConstants } from "../../constants";

const HotPassTable = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const PqrReportData =
    useSelector((state) => state.pqrdataReducer.pqrdataList) || [];
  const { passname, weld_number } = location.state || {};
  const [pageSize, setPageSize] = useState(commonConstants.defaultPageSize);

  const queryParams = new URLSearchParams(location.search);
  const boundary = queryParams.get("boundary");

  const wpsLimitDataRecords = PqrReportData?.records?.[passname] || [];
  const pqrtlogTableColumns = [
    {
      title: t("pqrReport.passName"),
      dataIndex: "passName",
      key: "passName",
      position: 0,
      width: 125,
    },
    {
      title: t("pqrReport.weldNo"),
      dataIndex: "weldNumber",
      key: "weldNumber",
      position: 1,
      width: 125,
    },
    {
      title: t("pqrReport.torch"),
      dataIndex: "torch",
      key: "torch",
      position: 2,
      width: 125,
    },
    {
      title: "Bug Type",
      dataIndex: "bugType",
      key: "bugType",
      position: 3,
      width: 125,
    },
    {
      title: (
        <div>
          {" "}
          {t("pqrReport.preHeat")} <br /> (°C){" "}
        </div>
      ),
      position: 4,
      width: 125,
      dataIndex: "preHeatInterPassTemperature",
      key: "preHeatInterPassTemperature",
    },
    {
      title: (
        <div>
          {t("pqrReport.avgVoltage")} <br /> (V)
        </div>
      ),
      position: 5,
      width: 125,
      dataIndex: "averageVoltage",
      key: "averageVoltage",
    },
    {
      title: (
        <div>
          {t("pqrReport.avgCurrent")} <br /> (A)
        </div>
      ),
      position: 6,
      width: 125,
      dataIndex: "averageCurrent",
      key: "averageCurrent",
    },
    {
      title: (
        <div>
          {t("pqrReport.avgWireFeedSpeed")} <br /> (in/min)
        </div>
      ),
      position: 7,
      width: 125,
      dataIndex: "averageWireFeedSpeed",
      key: "averageWireFeedSpeed",
    },
    {
      title: (
        <div>
          {t("pqrReport.avgOscillationWidth")} <br /> (in)
        </div>
      ),
      position: 8,
      width: 125,
      dataIndex: "averageOscillationWidth",
      key: "averageOscillationWidth",
    },
    {
      title: (
        <div>
          {t("pqrReport.avgOscillationFrq")} <br /> (Hz)
        </div>
      ),
      position: 9,
      width: 125,
      dataIndex: "averageOscillationFrequency",
      key: "averageOscillationFrequency",
    },
    {
      title: (
        <div>
          {t("pqrReport.avgTravelSpeed")} <br /> (in/min)
        </div>
      ),
      position: 10,
      width: 125,
      dataIndex: "averageTravelSpeed",
      key: "averageTravelSpeed",
    },
    {
      title: (
        <div>
          {t("pqrReport.arcEnergy")} <br /> (kJ/mm)
        </div>
      ),
      position: 11,
      width: 125,
      dataIndex: "arcEnergy",
      key: "arcEnergy",
    },
    {
      title: (
        <div>
          {t("pqrReport.avgPulseFrequency")} <br /> (Hz)
        </div>
      ),
      position: 12,
      width: 125,
      dataIndex: "averagePulseFrequency",
      key: "averagePulseFrequency",
    },
    {
      title: (
        <div>
          {t("pqrReport.avgPulseTime")} <br /> (ms)
        </div>
      ),
      position: 13,
      width: 125,
      dataIndex: "averagePulseTime",
      key: "averagePulseTime",
    },
    {
      title: (
        <div>
          {t("pqrReport.avgPeakCurrent")} <br /> (A)
        </div>
      ),
      position: 14,
      width: 125,
      dataIndex: "averagePeakCurrent",
      key: "averagePeakCurrent",
    },
    {
      title: (
        <div>
          {t("pqrReport.avgBaseCurrent")} <br /> (A)
        </div>
      ),
      position: 15,
      width: 125,
      dataIndex: "averageBaseCurrent",
      key: "averageBaseCurrent",
    },
  ];

  console.log(wpsLimitDataRecords, "wpsLimitDataRecords");
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [tlodVisibleColumns, setTlodVisibleColumns] =
    useState(pqrtlogTableColumns);
  const [tlogRemovedColumns, setTlogRemovedColumns] = useState([]);
  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };
  const removeColumn = (key, setVisible, setRemoved, visible, removed) => {
    if (visible.length <= 1) {
      notification.warning({
        message:
          "You cannot remove a column, at least one must remain in view.",
      });
      return;
    }
    const removedColumn = visible.find((col) => col.key === key);
    if (removedColumn) {
      const updatedColumns = visible.filter((col) => col.key !== key);
      setVisible(updatedColumns);
      setRemoved([...removed, removedColumn]);
    }
  };

  const addBackColumn = (key, setVisible, setRemoved, visible, removed) => {
    const addedColumn = removed.find((col) => col.key === key);
    if (addedColumn) {
      const updatedRemovedColumns = removed.filter((col) => col.key !== key);
      setRemoved(updatedRemovedColumns);

      // Find the index where the column should be added back
      let insertIndex = visible.findIndex(
        (col) => col.position > addedColumn.position
      );
      if (insertIndex === -1) {
        insertIndex = visible.length;
      }

      // Insert the column back at the correct position
      const updatedVisibleColumns = [
        ...visible.slice(0, insertIndex),
        addedColumn,
        ...visible.slice(insertIndex),
      ];
      setVisible(updatedVisibleColumns);
    }
  };

  const renderColumnOptions = (
    visibleColumns,
    removedColumns,
    setVisibleColumns,
    setRemovedColumns
  ) => (
    <List
      dataSource={[...visibleColumns, ...removedColumns]}
      renderItem={(item) => (
        <List.Item
          actions={[
            removedColumns.includes(item) ? (
              <Button
                key={`${item.key}-add`}
                onClick={() =>
                  addBackColumn(
                    item.key,
                    setVisibleColumns,
                    setRemovedColumns,
                    visibleColumns,
                    removedColumns
                  )
                }
              >
                Add Back
              </Button>
            ) : (
              <Button
                key={`${item.key}-remove`}
                onClick={() =>
                  removeColumn(
                    item.key,
                    setVisibleColumns,
                    setRemovedColumns,
                    visibleColumns,
                    removedColumns
                  )
                }
              >
                Remove
              </Button>
            ),
          ]}
        >
          {item.title}
        </List.Item>
      )}
    />
  );

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
  };

  const paginationOptions = {
    pageSize,
    onShowSizeChange: handlePageSizeChange,
    showSizeChanger: true,
    pageSizeOptions: commonConstants.ZonePageSizeOptions,
  };

  const getScrollHeight = () => {
    const screenHeight = window.innerHeight;

    if (screenHeight >= 832 && screenHeight <= 956) {
      return 520;
    } else {
      return 320;
    }
  };

  const handleRowClick = (record) => {
    history.push(`/tlog-data/${record.passName}?boundary=${boundary}`, {
      weld_no: record.weldNumber,
      Bugtype: record.torch || "iwm",
      deviceid: record.s_deviceid_event,
      s_deviceid_event: record.s_deviceid_event,
      weldNumber: weld_number,
      weld_number: weld_number,
      type: record.torch,
      bugTypepqr: record.bugType,
      passn: passname,
      station_number: record.station_number,
    });
  };

  return (
    <div>
      <div className="slogweld-title">
        <LeftOutlined className="leftoutlined" />
        <Link to={`/data360-home/pqr-report`}>
          <h2 className="slogweld-header-active">PQR Report</h2>
        </Link>
        <LeftOutlined className="leftoutlined" />
        <Link to={`/pqr-table-data/${weld_number}?boundary=${boundary}`}>
          <h2 className="slogweld-header-active">Weld {weld_number}</h2>
        </Link>
        <LeftOutlined className="leftoutlined" />
        <h2 className="slogweld-header">{passname}</h2>
        <Button
          key="cancel"
          type="secondary"
          className="column-option"
          onClick={showDrawer}
        >
          <MenuFoldOutlined />
          Column Options
        </Button>
      </div>
      <>
        <Table
          className="zoneview-table pqrstyle"
          dataSource={wpsLimitDataRecords}
          scroll={{ x: 2000, y: getScrollHeight() }}
          pagination={paginationOptions}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
        >
          {tlodVisibleColumns.map((column) =>
            column.children ? (
              <Table.ColumnGroup key={column.key} title={column.title}>
                {column.children.map((child) => (
                  <Table.Column
                    key={child.key}
                    title={child.title}
                    dataIndex={child.dataIndex}
                    width={column.width || 100}
                  />
                ))}
              </Table.ColumnGroup>
            ) : (
              <Table.Column
                key={column.key}
                title={column.title}
                dataIndex={column.dataIndex}
                width={column.width || 100}
              />
            )
          )}
        </Table>
      </>
      <Drawer
        title="Column Options"
        className="drawer"
        width={500}
        placement="right"
        closable={true}
        onClose={closeDrawer}
        open={drawerVisible}
      >
        {renderColumnOptions(
          tlodVisibleColumns,
          tlogRemovedColumns,
          setTlodVisibleColumns,
          setTlogRemovedColumns
        )}
      </Drawer>
    </div>
  );
};

export default HotPassTable;
