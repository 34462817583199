import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { commonConstants } from "../../constants";
import { sLogDataHelper } from "../../helpers";
import { setSlogsList, startSlogsLoader } from "../../redux/sLogdata/actions";
import { FETCH_SLOGS_SAGA } from "../../redux/sLogdata/types";
import CommonLoader from "../Loaders/loader";
// import SlogStatusGrid from "../AgGrid/slog-status";

const LogData = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const reducer = useSelector((state) => state.slogReducer);
  const hideshow = useSelector((state) => state.switchThemeReducer);

  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(commonConstants.defaultPageSize);

  const loader = reducer?.isSlogsLoading;
  const filteredData = useSelector(
    (state) => state.slogReducer.filteredTableData
  );

  const selectedProjectId = useSelector(
    (state) => state?.projectReducer?.selectedProject?.project_id
  );
  const selectedProjectName = useSelector(
    (state) => state?.projectReducer?.selectedProject?.project_name
  );
  const selectedProject = useSelector(
    (state) => state?.projectReducer?.selectedProject
  );

  useEffect(() => {
    if (selectedProjectId) {
      setPageSize(commonConstants.defaultPageSize);
    }
  }, [selectedProjectId, filteredData.length]);

  const loadMoreData = () => {
    dispatch(setSlogsList([], true));
    dispatch(startSlogsLoader());
    dispatch({
      type: FETCH_SLOGS_SAGA,
      payload: {
        project_id: selectedProjectId,
        project_name: selectedProjectName,
      },
    });
  };

  useEffect(() => {
    setFilters({
      slNo: "",
      pipeID: "",
      spread: "",
      subSpread: "",
      status: "",
    });

    if (selectedProjectId) {
      loadMoreData();
    }
  }, [dispatch, selectedProjectId, selectedProjectName]);

  const handleFilterChange = (value, dataIndex) => {
    const newFilters = { ...filters, [dataIndex]: value };

    if (value === undefined || (Array.isArray(value) && value.length === 0)) {
      delete newFilters[dataIndex];
    }

    setFilters(newFilters);
  };

  const dataSource = filteredData.map((item, index) => {
    const { weld_number, status, weld_time } = item;
    return {
      key: index,
      sl_no: index + 1,
      weldNumber: weld_number,
      weld_time: weld_time || "-",
      status: status || "Unknown",
    };
  });

  const columns = sLogDataHelper.getColumns(
    t,
    handleFilterChange,
    selectedProject,
    dispatch
  );

  const [filters, setFilters] = useState({
    sl_no: "",
    weldNumber: "",
    status: "",
    weld_time: "",
  });

  const handleRowClick = (record) => {
    history.push(`/slog-weld/${record.weldNumber}`);
  };

  const handleScroll = (e) => {
    const tableBody = e.target;
    if (
      tableBody.scrollTop + tableBody.clientHeight >=
      tableBody.scrollHeight
    ) {
      loadMoreData();
    }
  };

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
  };

  const paginationOptions = {
    pageSize,
    onShowSizeChange: handlePageSizeChange,
    showSizeChanger: true,
    pageSizeOptions: commonConstants.ZonePageSizeOptions,
  };

  const toggleProjectList = hideshow?.toggleProjectList;
  const toggleProjectDetails = hideshow?.toggleProjectDetails;

  const getScrollHeight = () => {
    const screenHeight = window.innerHeight;
    let baseHeight = 500;
    if (screenHeight >= 750 && screenHeight <= 832) {
      if (toggleProjectList && toggleProjectDetails) {
        return 320;
      } else if (!toggleProjectList && !toggleProjectDetails) {
        return 510;
      } else if (!toggleProjectList && toggleProjectDetails) {
        return 430;
      } else if (toggleProjectList && !toggleProjectDetails) {
        return 400;
      }
    } else {
      if (screenHeight >= 832 && screenHeight <= 956) {
        if (toggleProjectList && toggleProjectDetails) {
          return baseHeight - 80;
        } else if (!toggleProjectList && !toggleProjectDetails) {
          return baseHeight + 120;
        } else if (!toggleProjectList && toggleProjectDetails) {
          return baseHeight + 40;
        } else if (toggleProjectList && !toggleProjectDetails) {
          return baseHeight;
        }
      } else {
        if (toggleProjectList && toggleProjectDetails) {
          return 220;
        } else if (!toggleProjectList && !toggleProjectDetails) {
          return 410;
        } else if (!toggleProjectList && toggleProjectDetails) {
          return 340;
        } else if (toggleProjectList && !toggleProjectDetails) {
          return 292;
        }
        return 292;
      }
    }
  };

  return (
    <>
      {loader && filteredData.length === 0 ? (
        <div className="slogdata-loader">
          <CommonLoader />
        </div>
      ) : (
        <div className="custom-table-wrapper">
          {filteredData.length > 0 ? (
            <>
              <Table
                className={`slogdata-status reduced-width-table`}
                dataSource={dataSource}
                columns={columns}
                pagination={paginationOptions}
                scroll={{ y: getScrollHeight() }}
                bordered
                onRow={(record) => ({
                  onClick: () => handleRowClick(record),
                })}
                onScroll={handleScroll}
              />

              {/* <SlogStatusGrid
                rows={dataSource}
                isLoading={loader}
                handleRowClick={handleRowClick}
              /> */}
            </>
          ) : (
            <div className="slog-NoData">
              <p>{t("sLogData.weldlognotAvailable")}</p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default LogData;
