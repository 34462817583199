import { notification } from "antd";
import { put, call, all, takeLatest } from "redux-saga/effects";
import { startSlogReportLoader, stopSlogReportLoader } from "./actions";
import {
  FETCH_PQR_REPORT_SAGA,
  FETCH_SLOGS_REPORT_SAGA,
  SEND_PASS_VIEW_EMAIL,
  SEND_ZONE_VIEW_EMAIL,
} from "./types";
import { BASE_URL } from "../../config";
import fetch from "../../services/fetch";

function* fetchSlogsReportSaga(action) {
  try {
    const apiPayload = { ...action.payload };

    yield put(startSlogReportLoader());

    const res = yield fetch(
      `${BASE_URL}/v1/exposed/download-proxy-tlog?${new URLSearchParams(
        apiPayload
      )}`,
      {
        method: "GET",
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      }
    );

    if (res.status === 403) {
      throw new Error("Unauthorized");
    }

    const response = yield res.json();

    switch (response.status) {
      case 200:
        if (typeof action.cb === "function") {
          action.cb();
        }
        break;
      case 410:
        notification.warn({
          message: response?.message,
          duration: 6,
        });
        if (typeof action.error === "function") {
          action.error();
        }
        break;
      default:
        notification.error({ message: "Something went wrong." });
        if (typeof action.error === "function") {
          action.error();
        }
    }
    yield put(stopSlogReportLoader());
  } catch (e) {
    yield put(stopSlogReportLoader());
    notification.error({ message: e.message || "An error occurred." });
    if (typeof action.error === "function") {
      action.error();
    }
  }
}

function* fetchPqrReportSaga(action) {
  try {
    const apiPayload = { ...action.payload };

    yield put(startSlogReportLoader());

    const res = yield fetch(
      `${BASE_URL}/v1/exposed/pqr-report?${new URLSearchParams(apiPayload)}`,
      {
        method: "GET",
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      }
    );

    if (res.status === 403) {
      throw new Error("Unauthorized");
    }

    const response = yield res.json();

    switch (response.status) {
      case 200:
        if (typeof action.cb === "function") {
          action.cb();
        }
        break;
      case 410:
        notification.warn({
          message: response?.message,
          duration: 6,
        });
        if (typeof action.error === "function") {
          action.error();
        }
        break;
      default:
        notification.error({ message: "Something went wrong." });
        if (typeof action.error === "function") {
          action.error();
        }
    }
    yield put(stopSlogReportLoader());
  } catch (e) {
    yield put(stopSlogReportLoader());
    notification.error({ message: e.message || "An error occurred." });
    if (typeof action.error === "function") {
      action.error();
    }
  }
}

function* sendZoneEmail(action) {
  try {
    const apiPayload = { ...action.payload };
    yield put(startSlogReportLoader());

    const res = yield fetch(
      `${BASE_URL}/v1/exposed/download-proxy-zone-view?${new URLSearchParams(
        apiPayload
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "application/pdf",
        },
      }
    );

    if (res.status === 403) {
      throw new Error("Unauthorized");
    }

    const response = yield res.json();

    switch (response.status) {
      case 200:
        if (typeof action.cb === "function") {
          action.cb();
        }
        break;
      case 410:
        notification.warn({
          message: response?.message,
          duration: 6,
        });
        if (typeof action.error === "function") {
          action.error();
        }
        break;
      default:
        notification.error({ message: "Something went wrong." });
        if (typeof action.error === "function") {
          action.error();
        }
    }
    yield put(stopSlogReportLoader());
  } catch (e) {
    yield put(stopSlogReportLoader());
    notification.error({ message: e.message || "An error occurred." });
    if (typeof action.error === "function") {
      action.error();
    }
  }
}
function* sendPassEmail(action) {
  try {
    const apiPayload = { ...action.payload };
    yield put(startSlogReportLoader());

    const res = yield fetch(
      `${BASE_URL}/v1/exposed/download-proxy-pass-view?${new URLSearchParams(
        apiPayload
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "application/pdf",
        },
      }
    );

    if (res.status === 403) {
      throw new Error("Unauthorized");
    }

    const response = yield res.json();

    switch (response.status) {
      case 200:
        if (typeof action.cb === "function") {
          action.cb();
        }
        break;
      case 410:
        notification.warn({
          message: response?.message,
          duration: 6,
        });
        if (typeof action.error === "function") {
          action.error();
        }
        break;
      default:
        notification.error({ message: "Something went wrong." });
        if (typeof action.error === "function") {
          action.error();
        }
    }
    yield put(stopSlogReportLoader());
  } catch (e) {
    yield put(stopSlogReportLoader());
    notification.error({ message: e.message || "An error occurred." });
    if (typeof action.error === "function") {
      action.error();
    }
  }
}

function* slogsReportWatcher() {
  yield takeLatest(FETCH_SLOGS_REPORT_SAGA, fetchSlogsReportSaga);
  yield takeLatest(FETCH_PQR_REPORT_SAGA, fetchPqrReportSaga);
  yield takeLatest(SEND_ZONE_VIEW_EMAIL, sendZoneEmail);
  yield takeLatest(SEND_PASS_VIEW_EMAIL, sendPassEmail);
}

function* slogsReportSagas() {
  yield all([call(slogsReportWatcher)]);
}

export default slogsReportSagas;
