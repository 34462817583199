import moment from "moment";

const dateFilter = (filterLocalDateAtMidnight, cellValue) => {
  const dateAsString = moment(1000000 * cellValue).format("DD/MM/YYYY");

  if (dateAsString == null) {
    return 0;
  }

  // In the example application, dates are stored as dd/mm/yyyy
  // We create a Date object for comparison against the filter date
  const dateParts = dateAsString.split("/");
  const year = Number(dateParts[2]);
  const month = Number(dateParts[1]) - 1;
  const day = Number(dateParts[0]);
  const cellDate = new Date(year, month, day);

  // Now that both parameters are Date objects, we can compare

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  } else if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
  return 0;
};

const aggridHelpers = {
  dateFilter,
};

export { aggridHelpers };
