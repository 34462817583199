import { Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ExclamationIcon from "../../assets/exclamation.svg";
import { commonConstants } from "../../constants";
import { logDataHelperFunctions } from "../../helpers/logdata-helper";
import { tlogMapData } from "../../helpers/tlog/tlog-data-mapper";
import { tlogHelper } from "../../helpers/tlog/tlog-helper";
import { startTlogsLoader } from "../../redux/tLogData/actions";
import { FETCH_TLOGS_SAGA } from "../../redux/tLogData/type";
import TlogGrid from "../AgGrid/Tlog-View";
import CommonLoader from "../Loaders/loader";

const TlogTableView = ({
  s_deviceid_event,
  station_number,
  type,
  zone,
  weld_number,
  tlodVisibleColumns,
  setSelectedRowKeys,
  selectedRowKeys = [],
  boundary,
  tilt_start,
  tilt_end,
  isM500,
  rawZoneView,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reducer = useSelector((state) => state.tlogsReducer);
  const [pageSize, setPageSize] = useState(commonConstants.defaultPageSize);
  const loader = reducer?.isTlogsLoading;
  const location = useLocation();
  const [weldParamsShowColumns, setWeldParamsShowColumns] = useState([]);

  const { projectReducer } = useSelector((state) => state);
  const { weld_no, Bugtype, deviceid } = location.state || {};

  const [reportColumns, setReportColumns] = useState(
    logDataHelperFunctions.sampleReportColumns
  );

  useEffect(() => {
    if (projectReducer?.selectedProject?.reportColumns) {
      try {
        let parsedData = JSON.parse(
          projectReducer?.selectedProject?.reportColumns
        );
        setReportColumns(parsedData);
      } catch (e) {
        console.log(e);
      }
    }
  }, [projectReducer?.selectedProject?.reportColumns]);

  const isFromPQRTab = weld_no && Bugtype && deviceid;

  useEffect(() => {
    if (reducer?.tlogsList?.weldParams?.length > 0) {
      let allMappedValues = reducer?.tlogsList?.weldParams
        ?.map((ite) => {
          try {
            let params = ite.parameter_check_list || {};
            let enabledKeys = [];
            for (let key of Object.keys(params)) {
              if (params[key]) {
                enabledKeys.push(key);
              }
            }

            return {
              job_number: ite.job_number,
              enabledKeys:
                enabledKeys?.length > 0
                  ? enabledKeys
                  : logDataHelperFunctions.defaultParamsValues,
            };
          } catch (e) {
            console.log(e);
            return null;
          }
        })
        .filter((ite) => ite != null);

      setWeldParamsShowColumns(allMappedValues);
    }
  }, [reducer?.tlogsList?.weldParams]);

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
  };

  const selectedProjectId = useSelector(
    (state) => state?.projectReducer?.selectedProject?.project_id
  );
  const selectedProjectName = useSelector(
    (state) => state?.projectReducer?.selectedProject?.project_name
  );
  const selectedProject = useSelector(
    (state) => state?.projectReducer?.selectedProject
  );
  const tlogData = reducer?.tlogsList?.allTlogs?.recordList || [];
  const tableLimitData = reducer?.tlogsList?.tableLimitData?.recordList || [];

  useEffect(() => {
    if (selectedProject) {
      const params = {
        // skip: 0,
        project_id: selectedProjectId,
        project_name: selectedProjectName,
        deviceid_event: s_deviceid_event,
        weld_number: weld_number,
        zone: isM500 ? rawZoneView : zone,
        type: type,
      };

      dispatch(startTlogsLoader());
      dispatch({ type: FETCH_TLOGS_SAGA, payload: params });
    }
  }, [
    dispatch,
    selectedProject,
    selectedProjectName,
    s_deviceid_event,
    selectedProjectId,
    weld_number,
    zone,
    type,
    dispatch,
  ]);

  useEffect(() => {
    if (selectedProject && isFromPQRTab) {
      const params = {
        project_id: selectedProjectId,
        project_name: selectedProjectName,
        deviceid_event: deviceid,
        weld_number: weld_no,
        type: Bugtype,
      };

      dispatch(startTlogsLoader());
      dispatch({ type: FETCH_TLOGS_SAGA, payload: params });
    }
  }, [
    dispatch,
    selectedProject,
    s_deviceid_event,
    selectedProjectId,
    weld_number,
    type,
    Bugtype,
  ]);

  const paginationOptions = {
    pageSize,
    onShowSizeChange: handlePageSizeChange,
    showSizeChanger: true,
    pageSizeOptions: commonConstants.ZonePageSizeOptions,
    className: "custom-pagination",
    getPopupContainer: (trigger) => trigger.parentElement || document.body,
  };

  const hideColumnsStationZero = [
    "tilt",
    "distance",
    "wireSpeed",
    "oscillationWidth",
    "target",
    "horizontalBias",
    "frequency",
    "totalWireConsumed",
    "trueEnergy",
  ];

  const showRangeColumns = [
    "volts",
    "current",
    "wireSpeed",
    "oscillationWidth",
    "trueEnergy",
    "heat",
    "travelSpeed",
  ];

  let mappingColumnTableLimit = tlogHelper.mappingColumnTableLimit;

  const getRangeColumn = ({ avgTlogItem, tableLimitData, type, column }) => {
    if (!avgTlogItem?.trail_pass_name && !avgTlogItem?.lead_pass_name) {
      return {
        doesExist: false,
        showColor: "",
      };
    }

    let passNameExtracted = "";
    if (type == "Lead") {
      passNameExtracted = avgTlogItem?.lead_pass_name;
    } else if (type == "Trail") {
      passNameExtracted = avgTlogItem?.trail_pass_name;
    } else {
      passNameExtracted =
        avgTlogItem?.trail_pass_name || avgTlogItem?.lead_pass_name;
    }
    if (passNameExtracted.includes("Head")) {
      passNameExtracted = "HEAD";
    }

    let job_number = avgTlogItem?.job_number;

    let allTableLimitZones = [];

    if (job_number) {
      allTableLimitZones = tableLimitData?.filter(
        (tableLimit) => tableLimit.job_number == job_number
      );
    }

    allTableLimitZones = allTableLimitZones?.filter(
      (tableLimit) => tableLimit.zone_name == passNameExtracted
    );

    let allPassNames = allTableLimitZones?.map((ite) => ite.pass_name);

    let uniquePassNames = [...new Set([...allPassNames])];

    if (uniquePassNames?.length == 0) {
      return {
        doesExist: false,
        showColor: "",
      };
    } else {
      return {
        doesExist: true,
        showColor:
          allTableLimitZones?.[0]?.[`${column}_min`] &&
          allTableLimitZones?.[0]?.[`${column}_max`]
            ? `[${tlogHelper.rangeCalculation(
                allTableLimitZones?.[0]?.[`${column}_min`],
                column,
                reportColumns,
                isFromPQRTab
              )} -  ${tlogHelper.rangeCalculation(
                allTableLimitZones?.[0]?.[`${column}_max`],
                column,
                reportColumns,
                isFromPQRTab
              )}]`
            : "",
      };
    }
  };

  let stationNumber = station_number;

  let tlogParsedData =
    tlogMapData({
      isFromPQRTab: isFromPQRTab,
      station_number: stationNumber,
      tableLimitData: tableLimitData,
      tlogRecords: tlogData,
      type: type,
      reportColumns: reportColumns,
      weldParamsShowColumns: weldParamsShowColumns,
      boundary: boundary,
      tilt_start,
      tilt_end,
    }) || [];
  const getScrollHeight = (isFromPQRTab = false) => {
    const screenHeight = window.innerHeight;
    const heightMap = [
      { max: 768, default: 300, pqr: 460 },
      { max: 900, default: 450, pqr: 600 },
      { max: 1050, default: 600, pqr: 750 },
      { max: 1200, default: 750, pqr: 900 },
      { max: 1440, default: 900, pqr: 1050 },
    ];

    for (const { max, default: def, pqr } of heightMap) {
      if (screenHeight <= max) return isFromPQRTab ? pqr : def;
    }

    return isFromPQRTab ? 1200 : 1000;
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const PqrreportLink = useSelector(
    (state) => state.pqrdataReducer?.pqrReportLinkData?.recordList
  );
  const excludedTlogsKey = PqrreportLink?.[0]?.excluded_tlogs_key || [];

  console.log(excludedTlogsKey, "excludedTlogsKey");

  // const excludedTlogsData = JSON.parse(excludedTlogsKey) || [];
  let excludedTlogsData;

  try {
    excludedTlogsData = excludedTlogsKey ? JSON.parse(excludedTlogsKey) : [];
  } catch (error) {
    console.error("Failed to parse excludedTlogsKey:", error);
    excludedTlogsData = [];
  }

  const excludedTlogsValues = Array.isArray(
    excludedTlogsData?.excluded_tlogs_key?.[0]?.excluded_tlogs
  )
    ? excludedTlogsData.excluded_tlogs_key[0].excluded_tlogs
    : [];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    // getCheckboxProps: (record) => ({
    //   disabled: excludedTlogsValues.includes(record.event),
    // }),
    columnTitle: () => <span>Remove Row</span>,
    renderCell: (checked, record, index, originNode) => {
      return (
        <Tooltip
          title={
            excludedTlogsValues.includes(record.event)
              ? "value is used already"
              : ""
          }
        >
          {originNode}
        </Tooltip>
      );
    },
  };

  const hasValidM500Data = tlogParsedData.some(
    (record) =>
      record.headAngle !== "" ||
      record.arcImpedance !== "" ||
      (record.m500Data && record.m500Data.PipeNum !== "-")
  );

  return (
    <div>
      {loader ? (
        <div className="slogdata-loader">
          <CommonLoader />
        </div>
      ) : (
        <>
          <>
            {tlogParsedData.length > 0 ? (
              <>
                {isFromPQRTab ? (
                  <Table
                    rowSelection={
                      isFromPQRTab
                        ? {
                            ...rowSelection,
                            columnWidth: 100,
                          }
                        : undefined
                    }
                    className={
                      isFromPQRTab
                        ? `zoneview-table tlogtable-style-hasdata`
                        : `zoneview-table tlogtable-style`
                    }
                    // className="zoneview-table tlogtable-style"
                    dataSource={tlogParsedData}
                    pagination={paginationOptions}
                    scroll={{ x: 2500, y: getScrollHeight(isFromPQRTab) }}
                    bordered
                    rowKey={(record) => record.event}
                  >
                    {tlogHelper
                      .mapTlogColumns(
                        tlodVisibleColumns,
                        reportColumns,
                        isFromPQRTab,
                        isM500
                      )
                      ?.filter((ite) =>
                        stationNumber == "0"
                          ? hideColumnsStationZero.includes(ite.dataIndex)
                            ? null
                            : ite
                          : ite
                      )
                      ?.filter((ite) => {
                        // If there's no m500 data, hide these columns
                        if (
                          !hasValidM500Data &&
                          (ite.dataIndex === "headAngle" ||
                            ite.dataIndex === "arcImpedance" ||
                            ite.dataIndex === "pipe")
                        ) {
                          return null;
                        }
                        return ite;
                      })
                      ?.filter((ite) => ite != null)
                      ?.map((column) => {
                        return (
                          <Table.Column
                            key={column.key}
                            title={
                              showRangeColumns?.includes(column.dataIndex)
                                ? column.title +
                                  " " +
                                  getRangeColumn({
                                    avgTlogItem: tlogData[0],
                                    column:
                                      mappingColumnTableLimit[column.dataIndex],
                                    tableLimitData: tableLimitData,
                                    type: type,
                                  })?.showColor
                                : column.title
                            }
                            dataIndex={column.dataIndex}
                            width={column.width || 100}
                            render={(text) => {
                              if (column.dataIndex === "event" && text) {
                                const eventText = text.split("_")[1];
                                return eventText || text;
                              }
                              if (column.dataIndex == "status") {
                                return text;
                              }

                              if (
                                column.dataIndex == "pass" ||
                                column.dataIndex == "zone"
                              ) {
                                return text == "!" ? (
                                  <img
                                    src={ExclamationIcon}
                                    alt="Exclamation"
                                  />
                                ) : (
                                  text
                                );
                              }

                              if (
                                text &&
                                text?.toString()?.includes("-color")
                              ) {
                                return (
                                  <span className="red-note">
                                    {text.replace("-color", "")}
                                  </span>
                                );
                              }
                              return text;
                            }}
                          />
                        );
                      })}
                  </Table>
                ) : (
                  <TlogGrid
                    reportColumns={reportColumns}
                    getRangeColumn={getRangeColumn}
                    hasValidM500Data={hasValidM500Data}
                    hideColumnsStationZero={hideColumnsStationZero}
                    isFromPQRTab={isFromPQRTab}
                    isM500={isM500}
                    showRangeColumns={showRangeColumns}
                    station_number={station_number}
                    tableLimitData={tableLimitData}
                    type={type}
                    rows={tlogParsedData}
                    tlogData={tlogData}
                    loading={loader}
                  />
                )}
              </>
            ) : (
              <div className="slog-NoData">
                <p>{t("sLogData.noDataAvailable")}</p>
              </div>
            )}
          </>
        </>
      )}
    </div>
  );
};

export default TlogTableView;
