import { notification } from "antd";
import { put, call, all, takeLatest } from "redux-saga/effects";
import { setSlogsList, startSlogsLoader, stopSlogsLoader } from "./actions";
import { FETCH_SLOGS_SAGA } from "./types";
import { BASE_URL } from "../../config";
import { projectionKeysSlog } from "../../helpers/s_logdata-helper";
import fetch from "../../services/fetch";

function* fetchSlogsSaga(action) {
  try {
    yield put(startSlogsLoader());

    // const batchSize = 1000;
    // let hasMoreData = true;
    // let initialLoad = true; // Flag to indicate initial data load

    let slogLoopContinue = true;

    const limit = 10000;
    let skip = 0;

    let allSlogs = [];
    let allSlogStatus = [];

    do {
      const { project_id, project_name } = action.payload;

      const url = new URL(`${BASE_URL}/v1/exposed/slog-details`);
      url.searchParams.append("skip", skip);
      url.searchParams.append("limit", limit);
      url.searchParams.append("onlySlogs", true);
      url.searchParams.append("projectionParams", projectionKeysSlog.join(","));

      if (project_id) url.searchParams.append("project_id", project_id);
      if (project_name) url.searchParams.append("project_name", project_name);

      const res = yield fetch(url.toString(), {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });

      if (res.status === 403) {
        throw new Error("Unauthorized");
      }

      const responseSlog = yield res.json();

      skip += limit;

      slogLoopContinue =
        responseSlog?.data?.recordSetSlog?.recordList?.length == limit;

      allSlogs = [...allSlogs, ...responseSlog.data?.recordSetSlog?.recordList];
    } while (slogLoopContinue);

    let allWeldNumbers = allSlogs.map((ite) => ite.weld_number);

    const uniqueWeldNumbers = [...new Set([...allWeldNumbers])];

    const { project_id, project_name } = action.payload;

    const slogStatusUrl = new URL(`${BASE_URL}/v1/exposed/slog-details`);
    slogStatusUrl.searchParams.append("onlyStatus", true);

    if (project_id) slogStatusUrl.searchParams.append("project_id", project_id);
    if (project_name)
      slogStatusUrl.searchParams.append("project_name", project_name);

    let slogRecordListApi = [];

    if (uniqueWeldNumbers?.length) {
      const res = yield fetch(slogStatusUrl.toString(), {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });

      if (res.status === 403) {
        throw new Error("Unauthorized");
      }

      const response = yield res.json();

      slogRecordListApi = response.data?.recordSetSlogStatus?.recordList;
    }

    allSlogStatus = [...allSlogStatus, ...slogRecordListApi];

    const result = [];

    uniqueWeldNumbers?.map((weldNumber) => {
      let slogs = allSlogs?.filter((ite) => ite.weld_number == weldNumber);
      let status = allSlogStatus.filter((ite) => ite.weld_number == weldNumber);

      result.push({
        weldNumber: weldNumber,
        slogs: slogs,
        slogStatus: status,
      });
    });

    yield put(setSlogsList(result, true));

    yield put(stopSlogsLoader());
  } catch (error) {
    console.log(error, "Failed to fetch slogs");
    notification.error({
      message: error.message || "Failed to fetch slogs.",
    });
    yield put(stopSlogsLoader());
  }
}

function* slogsWatcher() {
  yield takeLatest(FETCH_SLOGS_SAGA, fetchSlogsSaga);
}

function* slogsSagas() {
  yield all([call(slogsWatcher)]);
}

export default slogsSagas;
