import {
  SET_TILTVIEW_LIST,
  TILTVIEW_LOADER_START,
  TILTVIEW_LOADER_STOP,
} from "./type";

const initialState = {
  isTiewviewLoding: false,
  tiltviewList: [],
};

const tiltviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case TILTVIEW_LOADER_START: {
      return {
        ...state,
        isTiewviewLoding: true,
      };
    }

    case SET_TILTVIEW_LIST: {
      return {
        ...state,
        tiltviewList: action.payload,
      };
    }

    case TILTVIEW_LOADER_STOP: {
      return {
        ...state,
        isTiewviewLoding: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default tiltviewReducer;
