const slogKeys = {
  id: "id",
  s_logs_id: "s_logs_id",
  status: "status",
  deviceid_event: "deviceid_event",
  project_id: "project_id",
  project_name: "project_name",
  time: "time",
  bug_number: "bug_number",
  station_number: "station_number",
  bug_type: "bug_type",
  iwm: "iwm",
  pipe_diameter: "pipe_diameter",
  band_diameter: "band_diameter",
  logging_distance: "logging_distance",
  job_number: "job_number",
  weld_number: "weld_number",
  lead_wire_diameter: "lead_wire_diameter",
  trail_wire_diameter: "trail_wire_diameter",
  year: "year",
  month: "month",
  day: "day",
  hour: "hour",
  minute: "minute",
  second: "second",
  c_event: "c_event",
  c_time: "c_time",
  weld_time: "weld_time",
  gps: "gps",
  incomplete_flag: "incomplete_flag",
  auto_increment: "auto_increment",
  welder_id: "welder_id",
  param_checksum: "param_checksum",
};

const avgTLogsModelObject = {
  id: "id",
  avg_t_logs_id: "avg_t_logs_id",
  job_number: "job_number",
  project_id: "project_id",
  project_name: "project_name",
  s_deviceid_event: "s_deviceid_event",
  status: "status",
  avg_zone_status: "avg_zone_status",
  calculation_status: "calculation_status",
  lead_pass_name: "lead_pass_name",
  trail_pass_name: "trail_pass_name",
  travel_speed_avg: "travel_speed_avg",
  travel_speed_high: "travel_speed_high",
  travel_speed_low: "travel_speed_low",
  amps_avg: "amps_avg",
  amps_high: "amps_high",
  amps_low: "amps_low",
  volts_avg: "volts_avg",
  volts_high: "volts_high",
  volts_low: "volts_low",
  wire_speed_avg: "wire_speed_avg",
  wire_speed_high: "wire_speed_high",
  wire_speed_low: "wire_speed_low",
  frequency_avg: "frequency_avg",
  frequency_high: "frequency_high",
  frequency_low: "frequency_low",
  oscillate_width_avg: "oscillate_width_avg",
  oscillate_width_high: "oscillate_width_high",
  oscillate_width_low: "oscillate_width_low",
  target_avg: "target_avg",
  target_high: "target_high",
  target_low: "target_low",
  horizontal_bias_avg: "horizontal_bias_avg",
  horizontal_bias_high: "horizontal_bias_high",
  horizontal_bias_low: "horizontal_bias_low",
  total_wire_consumed_avg: "total_wire_consumed_avg",
  total_wire_consumed_high: "total_wire_consumed_high",
  total_wire_consumed_low: "total_wire_consumed_low",
  heat_avg: "heat_avg",
  heat_high: "heat_high",
  heat_low: "heat_low",
  calculated_heat_avg: "calculated_heat_avg",
  calculated_heat_high: "calculated_heat_high",
  calculated_heat_low: "calculated_heat_low",
  weld_time: "weld_time",
  s_time: "s_time",
  s_c_time: "s_c_time",
  machine_type: "machine_type",
  arc_impedance_avg: "arc_impedance_avg",
  arc_impedance_high: "arc_impedance_high",
  arc_impedance_low: "arc_impedance_low",
};

export { slogKeys, avgTLogsModelObject };
