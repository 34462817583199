// import { CloseCircleFilled /* FileExcelOutlined */ } from "@ant-design/icons";
// import { Button, Form /* , Input, Modal */ } from "antd";
// import React /* useState */ from "react";
import { slogKeys } from "./common/projection-keys";
import { logDataHelperFunctions } from "./logdata-helper";
// import { FETCH_PQR_REPORT_SAGA } from "../redux/reportGenerator/types";

// const { Item } = Form;

// Helper to stop event propagation
// const stopEventPropagation = (e) => e.stopPropagation();

// const EmailInputForm = ({ emails, setEmails, emailForm, validateEmail }) => {
//   const handleInputEnter = () => {
//     emailForm
//       .validateFields()
//       .then(() => {
//         const newEmail = emailForm.getFieldValue("additionalEmails").trim();
//         if (newEmail !== "" && !emails.includes(newEmail)) {
//           setEmails([newEmail, ...emails]);
//           emailForm.setFieldsValue({ additionalEmails: "" });
//         }
//       })
//       .catch((e) =>
//         console.error("Invalid Email : ", e.values.additionalEmails)
//       );
//   };

//   return (
//     <Form form={emailForm} onClick={stopEventPropagation}>
//       <Item name="additionalEmails" rules={[{ validator: validateEmail }]}>
//         <Input
//           placeholder="Enter email(s)"
//           onPressEnter={handleInputEnter}
//           onChange={stopEventPropagation}
//           disabled={emails.length > 4}
//         />
//       </Item>
//     </Form>
//   );
// };

// const EmailTagList = ({ emails, handleTagClose }) => (
//   <ol>
//     {emails.map((email, index) => (
//       <li key={email}>
//         <Button key={index} className="modal-email-button">
//           <div>{email}</div>
//         </Button>
//         <Button
//           onClick={(e) => {
//             stopEventPropagation(e);
//             handleTagClose(index);
//           }}
//           className="email-cancel-button"
//         >
//           <CloseCircleFilled />
//         </Button>
//       </li>
//     ))}
//   </ol>
// );

// const PqrReportModal = ({
//   isModalVisible,
//   handleOk,
//   handleCancel,
//   emails,
//   setEmails,
//   emailForm,
//   validateEmail,
//   handleTagClose,
// }) => (
//   <Modal
//     title="Download PQR Report"
//     open={isModalVisible}
//     onOk={handleOk}
//     onCancel={handleCancel}
//     okText="Send"
//     cancelText="Cancel"
//     onClick={stopEventPropagation}
//     okButtonProps={{ disabled: emails.length === 0 }}
//   >
//     <div className="email-popup-text" onClick={stopEventPropagation}>
//       <EmailTagList emails={emails} handleTagClose={handleTagClose} />
//       <EmailInputForm
//         emails={emails}
//         setEmails={setEmails}
//         emailForm={emailForm}
//         validateEmail={validateEmail}
//       />
//     </div>
//   </Modal>
// );

const getColumns = (
  t /* , handleFilterChange, selectedProject, dispatch */
) => {
  // const [isModalVisible, setIsModalVisible] = useState(false);
  // const [currentRecord, setCurrentRecord] = useState(null);
  // const [emails, setEmails] = useState([]);
  // const [emailForm] = Form.useForm();

  // const showModal = (e, record) => {
  //   stopEventPropagation(e);
  //   setCurrentRecord(record);
  //   if (emails.length === 0) {
  //     setIsModalVisible(true);
  //   } else {
  //     handleOk(e);
  //   }
  // };

  // const handleOk = (e) => {
  //   stopEventPropagation(e);
  //   downloadPqrExcel(
  //     currentRecord.weldNumber,
  //     selectedProject,
  //     dispatch,
  //     emails
  //   );
  //   setIsModalVisible(false);
  // };

  // const handleCancel = (e) => {
  //   stopEventPropagation(e);
  //   setIsModalVisible(false);
  // };

  // const handleTagClose = (index) => {
  //   const updatedEmails = emails.filter((_, i) => i !== index);
  //   setEmails(updatedEmails);
  // };

  // const validateEmail = (_, value) => {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  //   if (!value) {
  //     return Promise.resolve("Email is optional.");
  //   } else if (!emailRegex.test(value)) {
  //     return Promise.reject("Please enter a valid email address.");
  //   } else if (emails.includes(value)) {
  //     return Promise.reject("Please enter a non-duplicate email address.");
  //   }
  //   return Promise.resolve();
  // };

  return [
    {
      title: t("sLogData.slNo"),
      dataIndex: "sl_no",
      key: "sl_no",
      width: 250,
    },
    {
      title: t("sLogData.weldNumber"),
      dataIndex: "weldNumber",
      key: "weldNumber",
    },
    {
      title: t("sLogData.status"),
      dataIndex: "status",
      key: "status",
      render: (status) => logDataHelperFunctions.getIconForStatus(status),
    },
    {
      title: t("sLogData.weldTime"),
      dataIndex: "weld_time",
      key: "weld_time",
      render: (weld_time) => (weld_time === "0.00" ? "-" : weld_time),
    },
    // {
    //   title: "PQR Report",
    //   dataIndex: "pqrExcel",
    //   key: "pqrExcel",
    //   render: (_, record) => (
    //     <div onClick={stopEventPropagation}>
    //       <FileExcelOutlined
    //         onClick={(e) => showModal(e, record)}
    //         style={{ color: "#e03540", fontSize: "20px" }}
    //       />
    //       <PqrReportModal
    //         isModalVisible={isModalVisible}
    //         handleOk={handleOk}
    //         handleCancel={handleCancel}
    //         emails={emails}
    //         setEmails={setEmails}
    //         emailForm={emailForm}
    //         validateEmail={validateEmail}
    //         handleTagClose={handleTagClose}
    //       />
    //     </div>
    //   ),
    // },
  ];
};

// const downloadPqrExcel = (weldNum, selectedProject, dispatch, emails) => {
//   const payload = {
//     project_id: selectedProject.project_id,
//     project_name: selectedProject.project_name,
//     weld_numbers: weldNum,
//     email_address: emails.join(","),
//   };

//   dispatch({ type: FETCH_PQR_REPORT_SAGA, payload });
// };

const projectionKeysSlog = [
  slogKeys.id,
  slogKeys.s_logs_id,
  slogKeys.status,
  slogKeys.deviceid_event,
  slogKeys.project_id,
  slogKeys.project_name,
  slogKeys.time,
  slogKeys.bug_number,
  slogKeys.station_number,
  slogKeys.bug_type,
  slogKeys.iwm,
  slogKeys.weld_number,
  slogKeys.c_event,
  slogKeys.c_time,
  slogKeys.weld_time,
  slogKeys.gps,
  slogKeys.incomplete_flag,
  slogKeys.job_number,
  slogKeys.band_diameter,
  slogKeys.lead_wire_diameter,
  slogKeys.trail_wire_diameter,
  slogKeys.logging_distance,
  slogKeys.auto_increment,
  slogKeys.pipe_diameter,
];

export { getColumns, projectionKeysSlog };
