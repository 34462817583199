import { MenuOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Select } from "antd";
import React, { useEffect, useRef, useState, useMemo } from "react";
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import IWMGraph from "./IWMGraph";
import { tlogGraphHelperData } from "../../helpers";
import { tlogMapData } from "../../helpers/tlog/tlog-data-mapper";
import { tlogGraphHelper } from "../../helpers/tlog/tlog-graph";
import { tlogHelper } from "../../helpers/tlog/tlog-helper";
// import { convertValue } from "../../helpers/unit-conversion";
import { startTlogsLoader } from "../../redux/tLogData/actions";
import { FETCH_TLOGS_SAGA } from "../../redux/tLogData/type";
import CommonLoader from "../Loaders/loader";

const { Option } = Select;

const TLogGraph = ({
  s_deviceid_event,
  station_number,
  type,
  zone,
  weld_number,
  tilt_start,
  tilt_end,
}) => {
  const reducer = useSelector((state) => state.tlogsReducer);
  const reducerList = useSelector((state) => state);
  const themeState = reducerList?.switchThemeReducer?.switchTheme;
  const loader = reducer?.isTlogsLoading;
  const dispatch = useDispatch();

  const selectedProjectId = useSelector(
    (state) => state?.projectReducer?.selectedProject?.project_id
  );
  const selectedProjectName = useSelector(
    (state) => state?.projectReducer?.selectedProject?.project_name
  );
  const selectedProjectNumber = useSelector(
    (state) => state?.projectReducer?.selectedProject?.project_number
  );
  const selectedProject = useSelector(
    (state) => state?.projectReducer?.selectedProject
  );

  const { projectReducer } = useSelector((state) => state);

  const tlogData = reducer?.tlogsList?.allTlogs?.recordList || [];
  const tableLimitData = reducer?.tlogsList?.tableLimitData?.recordList || [];

  const colorOptions = [
    {
      value: "Travel Speed (in/min)",
      label: "Travel Speed (in/min)",
      key: "travelSpeed",
      color: "#e03540",
    },
    {
      value: "Volts (v)",
      key: "volt",
      label: "Volts (v)",
      color: "#FFA500",
    },
    {
      value: "Current (A)",
      key: "current",
      label: "Current (A)",
      color: "#ee82ee",
    },
    {
      value: "Wire Speed (in/min)",
      label: "Wire Speed (in/min)",
      key: "wireSpeed",
      color: "#800080",
    },
    {
      value: "Oscillate Width (in)",
      label: "Oscillate Width (in)",
      key: "oscillationWidth",
      color: "#A52A2A",
    },
    {
      value: "Target (A)",
      label: "Target (A)",
      key: "target",
      color: "#808080",
    },
    {
      value: "Horizontal Bias (A)",
      label: "Horizontal Bias (A)",
      key: "horizontalBias",
      color: "#00FFFF",
    },
    {
      value: "Frequency (cyc/min)",
      label: "Frequency (cyc/min)",
      key: "frequency",
      color: "#FFC0CB",
    },
    {
      value: "Total Wire Consumed (in)",
      label: "Total Wire Consumed (in)",
      key: "totalWireConsumed",
      color: "#FFFF00",
    },
    {
      value: "True Energy (KJ/in)",
      label: "True Energy (KJ/in)",
      key: "trueEnergy",
      color: "#0000FF",
    },
    {
      value: "Heat (kJ/in)",
      label: "Heat (kJ/in)",
      key: "heat",
      color: "#008000",
    },
    {
      value: "Arc Impedance (ohms)",
      label: "Arc Impedance (ohms)",
      key: "arcImpedance",
      color: "#FF6347",
    },
  ];

  const [reportColumns, setReportColumns] = useState({});

  useEffect(() => {
    if (projectReducer?.selectedProject?.reportColumns) {
      try {
        let parsedData = JSON.parse(
          projectReducer?.selectedProject?.reportColumns
        );
        setReportColumns(parsedData);
      } catch (e) {
        console.log(e);
      }
    }
  }, [projectReducer?.selectedProject?.reportColumns]);

  useEffect(() => {
    if (selectedProject) {
      const params = {
        project_id: selectedProjectId,
        project_name: selectedProjectName,
        deviceid_event: s_deviceid_event,
        weld_number: weld_number,
        zone: zone,
        type: type,
      };

      dispatch(startTlogsLoader());
      dispatch({ type: FETCH_TLOGS_SAGA, payload: params });
    }
  }, [
    dispatch,
    selectedProject,
    s_deviceid_event,
    selectedProjectId,
    selectedProjectName,
    weld_number,
    zone,
    type,
  ]);

  let passNameExtracted = "";
  if (type === "Lead") {
    passNameExtracted = tlogData[0]?.lead_pass_name || "";
  } else if (type === "Trail") {
    passNameExtracted = tlogData[0]?.trail_pass_name || "";
  } else {
    passNameExtracted =
      tlogData[0]?.trail_pass_name || tlogData[0]?.lead_pass_name || "";
  }

  if (passNameExtracted.includes("Head")) {
    passNameExtracted = "HEAD";
  }

  // Use useMemo to prevent recalculation on every render
  const tlogParsedData = useMemo(
    () =>
      tlogMapData({
        isFromPQRTab: false,
        station_number: station_number,
        tableLimitData: tableLimitData,
        tlogRecords: tlogData,
        type: type,
        reportColumns: reportColumns,
        weldParamsShowColumns: [],
        boundary: 0,
        tilt_start,
        tilt_end,
      }) || [],
    [
      station_number,
      tableLimitData,
      tlogData,
      type,
      reportColumns,
      tilt_start,
      tilt_end,
    ]
  );

  const [selectedOption, setSelectedOption] = useState("Chart 1");
  const [selectedOption2, setSelectedOption2] = useState("Chart 2");
  const [selectedOption3, setSelectedOption3] = useState("Chart 3");
  const [selectedOption4, setSelectedOption4] = useState("Chart 4");
  const [selectedOption5, setSelectedOption5] = useState("Chart 5");
  const [selectedOption6, setSelectedOption6] = useState("Chart 6");

  const cleanData = (dataArray) =>
    dataArray
      .filter((value) => value !== "" && !isNaN(parseFloat(value)))
      .map((value) => parseFloat(value));

  // Use useMemo for these derived values to prevent recalculation on every render
  const categories = useMemo(
    () => cleanData(tlogParsedData?.map((item) => item.tilt) || []),
    [tlogParsedData]
  );

  const travelSpeeds = useMemo(
    () =>
      cleanData(
        tlogParsedData?.map((item) => item.travelSpeed.split("-")[0]) || []
      ),
    [tlogParsedData]
  );

  const volts = useMemo(
    () =>
      cleanData(tlogParsedData?.map((item) => item.volts.split("-")[0]) || []),
    [tlogParsedData]
  );

  const current = useMemo(
    () =>
      cleanData(
        tlogParsedData?.map((item) => item.current.split("-")[0]) || []
      ),
    [tlogParsedData]
  );

  const wireSpeed = useMemo(
    () =>
      cleanData(
        tlogParsedData?.map((item) => item.wireSpeed.split("-")[0]) || []
      ),
    [tlogParsedData]
  );

  const oscillationWidth = useMemo(
    () =>
      cleanData(
        tlogParsedData?.map((item) => item.oscillationWidth.split("-")[0]) || []
      ),
    [tlogParsedData]
  );

  const target = useMemo(
    () =>
      cleanData(tlogParsedData?.map((item) => item.target.split("-")[0]) || []),
    [tlogParsedData]
  );

  const horizontalBias = useMemo(
    () =>
      cleanData(
        tlogParsedData?.map((item) => item.horizontalBias.split("-")[0]) || []
      ),
    [tlogParsedData]
  );

  const frequency = useMemo(
    () =>
      cleanData(
        tlogParsedData?.map((item) => item.frequency.split("-")[0]) || []
      ),
    [tlogParsedData]
  );

  const totalWireConsumed = useMemo(
    () =>
      cleanData(
        tlogParsedData?.map((item) => item.totalWireConsumed.split("-")[0]) ||
          []
      ),
    [tlogParsedData]
  );

  const trueEnergy = useMemo(
    () =>
      cleanData(
        tlogParsedData?.map((item) => item.trueEnergy.split("-")[0]) || []
      ),
    [tlogParsedData]
  );

  const heat = useMemo(
    () =>
      cleanData(tlogParsedData?.map((item) => item.heat.split("-")[0]) || []),
    [tlogParsedData]
  );

  const arcImpedance = useMemo(
    () =>
      cleanData(
        tlogParsedData?.map((item) => item.arcImpedance.split("-")[0]) || []
      ),
    [tlogParsedData]
  );

  const empty = [];

  const getMinMaxValues = (column) => {
    return tlogGraphHelper.getMinMaxValuesForGraph({
      column: column,
      tableLimitData: tableLimitData,
      tlogData: tlogData,
      type: type,
      reportColumns: reportColumns,
    });
  };

  const mappedColumns = useMemo(() => {
    const columns = tlogGraphHelper.mapTlogGraphColumns(
      colorOptions,
      reportColumns
    );

    // Filter out columns with no data
    return columns.filter((column) => {
      switch (column.key) {
        case tlogGraphHelper.columnsMapping.ARC_IMPEDANCE:
          return arcImpedance.length > 0;
        case tlogGraphHelper.columnsMapping.TRAVEL_SPEED:
          return travelSpeeds.length > 0;
        case tlogGraphHelper.columnsMapping.VOLT_V:
          return volts.length > 0;
        case tlogGraphHelper.columnsMapping.CURRENT_A:
          return current.length > 0;
        case tlogGraphHelper.columnsMapping.WIRE_SPEED:
          return wireSpeed.length > 0;
        case tlogGraphHelper.columnsMapping.OSCILLATION_WIDTH:
          return oscillationWidth.length > 0;
        case tlogGraphHelper.columnsMapping.TARGET:
          return target.length > 0;
        case tlogGraphHelper.columnsMapping.HORIZONTAL_BIAS:
          return horizontalBias.length > 0;
        case tlogGraphHelper.columnsMapping.FREQUENCY:
          return frequency.length > 0;
        case tlogGraphHelper.columnsMapping.TOTAL_WIRE_CONSUMED:
          return totalWireConsumed.length > 0;
        case tlogGraphHelper.columnsMapping.TRUE_ENERGY:
          return trueEnergy.length > 0;
        case tlogGraphHelper.columnsMapping.HEAT:
          return heat.length > 0;
        default:
          return true;
      }
    });
  }, [
    reportColumns,
    arcImpedance,
    travelSpeeds,
    volts,
    current,
    wireSpeed,
    oscillationWidth,
    target,
    horizontalBias,
    frequency,
    totalWireConsumed,
    trueEnergy,
    heat,
  ]);

  const processedDataRef = useRef({
    categories: [],
    graphData: {},
  });

  const getGraphData1 = useMemo(
    () => (selectedOption) => {
      let yAxisColor;
      let yAxisTitle;
      let minValue = 0;
      let maxValue = 0;
      let currentData = [];

      switch (selectedOption) {
        case "Travel Speed (in/min)":
          yAxisColor = "#e03540";
          yAxisTitle = mappedColumns.find(
            (ite) => ite.key == tlogGraphHelper.columnsMapping.TRAVEL_SPEED
          )?.label;
          ({ min: minValue, max: maxValue } = getMinMaxValues(
            tlogHelper.mappingColumnTableLimit.travelSpeed
          ));
          currentData = travelSpeeds;
          break;

        case "Volts (v)":
          yAxisColor = "#FFA500";
          yAxisTitle = mappedColumns.find(
            (ite) => ite.key == tlogGraphHelper.columnsMapping.VOLT_V
          )?.label;
          ({ min: minValue, max: maxValue } = getMinMaxValues(
            tlogHelper.mappingColumnTableLimit.volts
          ));
          currentData = volts;
          break;

        case "Current (A)":
          yAxisColor = "#ee82ee";
          yAxisTitle = mappedColumns.find(
            (ite) => ite.key == tlogGraphHelper.columnsMapping.CURRENT_A
          )?.label;
          ({ min: minValue, max: maxValue } = getMinMaxValues(
            tlogHelper.mappingColumnTableLimit.current
          ));
          currentData = current;
          break;

        case "Wire Speed (in/min)":
          yAxisColor = "#800080";
          yAxisTitle = mappedColumns.find(
            (ite) => ite.key == tlogGraphHelper.columnsMapping.WIRE_SPEED
          )?.label;
          ({ min: minValue, max: maxValue } = getMinMaxValues(
            tlogHelper.mappingColumnTableLimit.wireSpeed
          ));
          currentData = wireSpeed;
          break;

        case "Oscillate Width (in)":
          yAxisColor = "#A52A2A";
          yAxisTitle = mappedColumns.find(
            (ite) => ite.key == tlogGraphHelper.columnsMapping.OSCILLATION_WIDTH
          )?.label;
          ({ min: minValue, max: maxValue } = getMinMaxValues(
            tlogHelper.mappingColumnTableLimit.oscillationWidth
          ));
          currentData = oscillationWidth;
          break;

        case "Target (A)":
          yAxisColor = "#808080";
          yAxisTitle = mappedColumns.find(
            (ite) => ite.key == tlogGraphHelper.columnsMapping.TARGET
          )?.label;
          currentData = target;
          break;

        case "Horizontal Bias (A)":
          yAxisColor = "#00FFFF";
          yAxisTitle = mappedColumns.find(
            (ite) => ite.key == tlogGraphHelper.columnsMapping.HORIZONTAL_BIAS
          )?.label;
          currentData = horizontalBias;
          break;

        case "Frequency (cyc/min)":
          yAxisColor = "#FFC0CB";
          yAxisTitle = mappedColumns.find(
            (ite) => ite.key == tlogGraphHelper.columnsMapping.FREQUENCY
          )?.label;
          currentData = frequency;
          break;

        case "Total Wire Consumed (in)":
          yAxisColor = "#FFFF00";
          yAxisTitle = mappedColumns.find(
            (ite) =>
              ite.key == tlogGraphHelper.columnsMapping.TOTAL_WIRE_CONSUMED
          )?.label;
          currentData = totalWireConsumed;
          break;

        case "True Energy (KJ/in)":
          yAxisColor = "#0000FF";
          yAxisTitle = mappedColumns.find(
            (ite) => ite.key == tlogGraphHelper.columnsMapping.TRUE_ENERGY
          )?.label;
          ({ min: minValue, max: maxValue } = getMinMaxValues(
            tlogHelper.mappingColumnTableLimit.trueEnergy
          ));
          currentData = trueEnergy;
          break;

        case "Heat (kJ/in)":
          yAxisColor = "#008000";
          yAxisTitle = mappedColumns.find(
            (ite) => ite.key == tlogGraphHelper.columnsMapping.HEAT
          )?.label;
          ({ min: minValue, max: maxValue } = getMinMaxValues(
            tlogHelper.mappingColumnTableLimit.heat
          ));
          currentData = heat;
          break;

        case "Arc Impedance (ohms)":
          yAxisColor = "#FF6347";
          yAxisTitle = mappedColumns.find(
            (ite) => ite.key == tlogGraphHelper.columnsMapping.ARC_IMPEDANCE
          )?.label;
          currentData = arcImpedance;
          break;

        default:
          yAxisColor = "#0000FF";
          yAxisTitle = "Chart 1";
          currentData = empty;
          break;
      }

      // Store the processed data in the ref for CSV download
      processedDataRef.current.categories = categories;
      processedDataRef.current.graphData[selectedOption] = {
        data: currentData,
        minValue,
        maxValue,
      };

      // Return the graph configuration
      return tlogGraphHelperData.GraphOne(
        themeState,
        currentData,
        categories,
        yAxisTitle,
        yAxisColor,
        minValue,
        maxValue,
        selectedProjectNumber,
        selectedProjectName
      );
    },
    [
      mappedColumns,
      travelSpeeds,
      volts,
      current,
      wireSpeed,
      oscillationWidth,
      target,
      horizontalBias,
      frequency,
      totalWireConsumed,
      trueEnergy,
      heat,
      categories,
      themeState,
      selectedProjectNumber,
      selectedProjectName,
      tableLimitData,
      tlogData,
      type,
      reportColumns,
      arcImpedance,
    ]
  );

  // Use useMemo for all graph data to avoid recalculation on each render
  const FirstGraph = useMemo(
    () => getGraphData1(selectedOption),
    [getGraphData1, selectedOption]
  );
  const SecondGraph = useMemo(
    () => getGraphData1(selectedOption2),
    [getGraphData1, selectedOption2]
  );
  const ThirdGraph = useMemo(
    () => getGraphData1(selectedOption3),
    [getGraphData1, selectedOption3]
  );
  const forthGraph = useMemo(
    () => getGraphData1(selectedOption4),
    [getGraphData1, selectedOption4]
  );
  const fifthGraph = useMemo(
    () => getGraphData1(selectedOption5),
    [getGraphData1, selectedOption5]
  );
  const sixthGraph = useMemo(
    () => getGraphData1(selectedOption6),
    [getGraphData1, selectedOption6]
  );

  const isDataEmpty = FirstGraph.series[0].data.length === 0;
  const isDataEmpty2 = SecondGraph.series[0].data.length === 0;
  const isDataEmpty3 = ThirdGraph.series[0].data.length === 0;
  const isDataEmpty4 = forthGraph.series[0].data.length === 0;
  const isDataEmpty5 = fifthGraph.series[0].data.length === 0;
  const isDataEmpty6 = sixthGraph.series[0].data.length === 0;

  if (!categories.length) {
    return (
      <>
        <IWMGraph
          reportColumns={reportColumns}
          tlogParsedData={tlogParsedData}
          loader={loader}
          tableLimitData={tableLimitData}
          tlogData={tlogData}
          type={type}
        />
      </>
    );
  }

  const downloadChartCSV = (graphData, selectedOption) => {
    const storedData = processedDataRef.current;
    const categories = storedData.categories;
    const currentGraphData = storedData.graphData[selectedOption];

    if (!categories.length || !currentGraphData) {
      console.error("No data available for download");
      return;
    }

    const mainData = currentGraphData.data;
    const minData =
      graphData.series.find((series) => series.name === "Min")?.data || [];
    const maxData =
      graphData.series.find((series) => series.name === "Max")?.data || [];

    const selectedOptionLabel = mappedColumns.find(
      (item) => item.value === selectedOption
    )?.label;

    let headers = ["Tlog", selectedOptionLabel];

    // Only add min/max headers if they have values greater than 0
    if (minData.length > 0 && minData[0] > 0) {
      headers.push("Min Limit");
    }
    if (maxData.length > 0 && maxData[0] > 0) {
      headers.push("Max Limit");
    }
    // Create rows with all data
    const rows = categories.map((category, index) => {
      let row = [category, mainData[index]];

      if (minData.length > 0 && minData[0] > 0) {
        row.push(minData[index]);
      }
      if (maxData.length > 0 && maxData[0] > 0) {
        row.push(maxData[index]);
      }

      return row;
    });

    const csvContent = [
      headers.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `${selectedProjectNumber}_${selectedProjectName}_${selectedOptionLabel}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const getChartMenu = (graphData, selectedOption) => (
    <Menu>
      <Menu.Item onClick={() => downloadChartCSV(graphData, selectedOption)}>
        Download CSV
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="tlog-graph">
      {loader ? (
        <div className="slogdata-loader">
          <CommonLoader />
        </div>
      ) : (
        <>
          <div className="grapview-selector">
            <Select
              value={selectedOption}
              onChange={(value) => setSelectedOption(value)}
              className="graph-select"
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {mappedColumns.map((option) => (
                <Option key={option.value} value={option.value}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span
                      style={{
                        display: "inline-block",
                        width: "10px",
                        height: "10px",
                        backgroundColor: option.color,
                        marginRight: "8px",
                      }}
                    />
                    <span style={{ textTransform: "math-auto" }}>
                      {option.label}
                    </span>
                  </div>
                </Option>
              ))}
            </Select>
            {/* Repeat similar pattern for other selects */}
            <Select
              value={selectedOption2}
              onChange={(value) => setSelectedOption2(value)}
              className="graph-select"
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {mappedColumns.map((option) => (
                <Option key={option.value} value={option.value}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span
                      style={{
                        display: "inline-block",
                        width: "10px",
                        height: "10px",
                        backgroundColor: option.color,
                        marginRight: "8px",
                      }}
                    />
                    <span style={{ textTransform: "math-auto" }}>
                      {option.label}
                    </span>
                  </div>
                </Option>
              ))}
            </Select>
            <Select
              value={selectedOption3}
              onChange={(value) => setSelectedOption3(value)}
              className="graph-select"
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {mappedColumns.map((option) => (
                <Option key={option.value} value={option.value}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span
                      style={{
                        display: "inline-block",
                        width: "10px",
                        height: "10px",
                        backgroundColor: option.color,
                        marginRight: "8px",
                      }}
                    />
                    <span style={{ textTransform: "math-auto" }}>
                      {option.label}
                    </span>
                  </div>
                </Option>
              ))}
            </Select>
            <Select
              value={selectedOption4}
              onChange={(value) => setSelectedOption4(value)}
              className="graph-select"
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {mappedColumns.map((option) => (
                <Option key={option.value} value={option.value}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span
                      style={{
                        display: "inline-block",
                        width: "10px",
                        height: "10px",
                        backgroundColor: option.color,
                        marginRight: "8px",
                      }}
                    />
                    <span style={{ textTransform: "math-auto" }}>
                      {option.label}
                    </span>
                  </div>
                </Option>
              ))}
            </Select>
            <Select
              value={selectedOption5}
              onChange={(value) => setSelectedOption5(value)}
              className="graph-select"
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {mappedColumns.map((option) => (
                <Option key={option.value} value={option.value}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span
                      style={{
                        display: "inline-block",
                        width: "10px",
                        height: "10px",
                        backgroundColor: option.color,
                        marginRight: "8px",
                      }}
                    />
                    <span style={{ textTransform: "math-auto" }}>
                      {option.label}
                    </span>
                  </div>
                </Option>
              ))}
            </Select>
            <Select
              value={selectedOption6}
              onChange={(value) => setSelectedOption6(value)}
              className="graph-select"
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {mappedColumns.map((option) => (
                <Option key={option.value} value={option.value}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span
                      style={{
                        display: "inline-block",
                        width: "10px",
                        height: "10px",
                        backgroundColor: option.color,
                        marginRight: "8px",
                      }}
                    />
                    <span style={{ textTransform: "math-auto" }}>
                      {option.label}
                    </span>
                  </div>
                </Option>
              ))}
            </Select>
          </div>
          <div className="line-graph">
            {!isDataEmpty && (
              <>
                <div className="menuOutlined">
                  <Dropdown
                    overlay={getChartMenu(FirstGraph, selectedOption)}
                    trigger={["click"]}
                    placement="bottomRight"
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    <MenuOutlined style={{ fontSize: "16px" }} />
                  </Dropdown>
                </div>
                <Chart
                  options={FirstGraph.options}
                  series={FirstGraph.series}
                  type="line"
                  height={250}
                  className="Graph-background"
                />
              </>
            )}
            {!isDataEmpty2 && (
              <>
                <div className="menuOutlined">
                  <Dropdown
                    overlay={getChartMenu(SecondGraph, selectedOption2)}
                    trigger={["click"]}
                    placement="bottomRight"
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    <MenuOutlined style={{ fontSize: "16px" }} />
                  </Dropdown>
                </div>
                <Chart
                  options={SecondGraph.options}
                  series={SecondGraph.series}
                  type="line"
                  height={250}
                  className="Graph-background"
                />
              </>
            )}
            {!isDataEmpty3 && (
              <>
                <div className="menuOutlined">
                  <Dropdown
                    overlay={getChartMenu(ThirdGraph, selectedOption3)}
                    trigger={["click"]}
                    placement="bottomRight"
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    <MenuOutlined style={{ fontSize: "16px" }} />
                  </Dropdown>
                </div>
                <Chart
                  options={ThirdGraph.options}
                  series={ThirdGraph.series}
                  type="line"
                  height={250}
                  className="Graph-background"
                />
              </>
            )}
            {!isDataEmpty4 && (
              <>
                <div className="menuOutlined">
                  <Dropdown
                    overlay={getChartMenu(forthGraph, selectedOption4)}
                    trigger={["click"]}
                    placement="bottomRight"
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    <MenuOutlined style={{ fontSize: "16px" }} />
                  </Dropdown>
                </div>
                <Chart
                  options={forthGraph.options}
                  series={forthGraph.series}
                  type="line"
                  height={250}
                  className="Graph-background"
                />
              </>
            )}
            {!isDataEmpty5 && (
              <>
                <div className="menuOutlined">
                  <Dropdown
                    overlay={getChartMenu(fifthGraph, selectedOption5)}
                    trigger={["click"]}
                    placement="bottomRight"
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    <MenuOutlined style={{ fontSize: "16px" }} />
                  </Dropdown>
                </div>
                <Chart
                  options={fifthGraph.options}
                  series={fifthGraph.series}
                  type="line"
                  height={250}
                  className="Graph-background"
                />
              </>
            )}
            {!isDataEmpty6 && (
              <>
                <div className="menuOutlined">
                  <Dropdown
                    overlay={getChartMenu(sixthGraph, selectedOption6)}
                    trigger={["click"]}
                    placement="bottomRight"
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    <MenuOutlined style={{ fontSize: "16px" }} />
                  </Dropdown>
                </div>
                <Chart
                  options={sixthGraph.options}
                  series={sixthGraph.series}
                  type="line"
                  height={250}
                  className="Graph-background"
                />
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default TLogGraph;
