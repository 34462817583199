import React, { useState } from "react";
import EnterpriseGridCommon from "./Ag-Grid";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useTranslation } from "react-i18next";
import ExclamationIcon from "../../assets/exclamation.svg";
import { logDataHelperFunctions } from "../../helpers/logdata-helper";
import { tlogHelper } from "../../helpers/tlog/tlog-helper";

const TlogGrid = ({
  rows,
  reportColumns,
  isFromPQRTab,
  isM500,
  station_number,
  hideColumnsStationZero,
  hasValidM500Data,
  showRangeColumns,
  getRangeColumn,
  tableLimitData,
  type,
  tlogData,
  loading,
}) => {
  const { t } = useTranslation();

  const valueRender = (params) => {
    const text = params.value;
    if (text?.includes("-color")) {
      return <span className="red-note">{text?.replace("-color", "")}</span>;
    }
    return isNaN(text) ? "" : text;
  };

  const [columnDefs] = useState([
    {
      headerName: t("slogColumntitle.sl.no"),
      field: "sl_no",
      key: "sl_no",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: false,
    },
    {
      headerName: t("slogColumntitle.status"),
      field: "statusNew",
      key: "status",
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: false,
      cellRenderer: (params) => {
        return params.value
          ? logDataHelperFunctions.getIconForStatus(params.value)
          : "";
      },
    },
    {
      headerName: t("slogColumntitle.event"),
      field: "event",
      key: "event",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: false,
    },
    {
      headerName: t("slogColumntitle.jobNumber"),
      field: "jobNumber",
      key: "jobNumber",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: true,
    },
    {
      headerName: t("slogColumntitle.time"),
      field: "time",
      key: "time",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: false,
    },
    {
      headerName: t("slogColumntitle.tilt"),
      field: "tilt",
      key: "tilt",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: false,
    },
    {
      headerName: t("slogColumntitle.pipe"),
      field: "pipe",
      key: "pipe",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: false,
      minWidth: 150,
    },
    {
      headerName: t("slogColumntitle.pass"),
      field: "pass",
      key: "pass",
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: true,
      cellRenderer: (params) => {
        return params.value == "!" ? (
          <img src={ExclamationIcon} alt="Exclamation" />
        ) : (
          params.value
        );
      },
    },
    {
      headerName: t("slogColumntitle.zone"),
      field: "zone",
      key: "zone",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: true,
      cellRenderer: (params) => {
        return params.value == "!" ? (
          <img src={ExclamationIcon} alt="Exclamation" />
        ) : (
          params.value
        );
      },
    },
    {
      headerName: t("slogColumntitle.distance"),
      field: "distance",
      key: "distance",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: false,
      cellRenderer: (params) => {
        return valueRender(params);
      },
    },
    {
      headerName: `${t("slogColumntitle.travelSpeed")} \n`,
      field: "travelSpeed",
      key: "travelSpeed",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: false,
      cellRenderer: (params) => {
        return valueRender(params);
      },
    },
    {
      headerName: `${t("slogColumntitle.volt")}`,
      field: "volts",
      key: "volts",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: false,
      cellRenderer: (params) => {
        return valueRender(params);
      },
    },
    {
      headerName: `${t("slogColumntitle.current")} \n`,
      field: "current",
      key: "current",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: false,
      cellRenderer: (params) => {
        return valueRender(params);
      },
    },
    {
      headerName: `${t("slogColumntitle.wireSpeed")}`,
      field: "wireSpeed",
      key: "wireSpeed",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: false,
      cellRenderer: (params) => {
        return valueRender(params);
      },
    },
    {
      headerName: t("slogColumntitle.oscillationWidth"),
      field: "oscillationWidth",
      key: "oscillationWidth",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: false,
      cellRenderer: (params) => {
        return valueRender(params);
      },
    },
    {
      headerName: t("slogColumntitle.target"),
      field: "target",
      key: "target",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: false,
      cellRenderer: (params) => {
        return valueRender(params);
      },
    },
    {
      headerName: t("slogColumntitle.horizontalBias"),
      field: "horizontalBias",
      key: "horizontalBias",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: false,
      cellRenderer: (params) => {
        return valueRender(params);
      },
    },
    {
      headerName: t("slogColumntitle.frequency"),
      field: "frequency",
      key: "frequency",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: false,
      cellRenderer: (params) => {
        return valueRender(params);
      },
    },
    {
      headerName: t("slogColumntitle.totalWireConsumed"),
      field: "totalWireConsumed",
      key: "totalWireConsumed",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: false,
      cellRenderer: (params) => {
        return valueRender(params);
      },
    },
    {
      headerName: t("slogColumntitle.trueEnergy"),
      field: "trueEnergy",
      key: "trueEnergy",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: false,
      cellRenderer: (params) => {
        return valueRender(params);
      },
    },
    {
      headerName: t("slogColumntitle.heat"),
      field: "heat",
      key: "heat",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: false,
      cellRenderer: (params) => {
        return valueRender(params);
      },
    },
    {
      headerName: t("slogColumntitle.headAngel"),
      field: "headAngle",
      key: "headAngle",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: false,
      cellRenderer: (params) => {
        return valueRender(params);
      },
    },
    {
      headerName: t("slogColumntitle.arcImpedance"),
      field: "arcImpedance",
      key: "arcImpedance",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: false,
      cellRenderer: (params) => {
        return valueRender(params);
      },
    },
  ]);

  let columnsFormatted = columnDefs?.filter((column) => {
    if (station_number === "0") {
      return !hideColumnsStationZero.includes(column.field);
    }
    return true;
  });

  columnsFormatted = tlogHelper
    .mapTlogColumns(columnsFormatted, reportColumns, isFromPQRTab, isM500)
    ?.filter((ite) =>
      station_number == "0"
        ? hideColumnsStationZero.includes(ite.dataIndex)
          ? null
          : ite
        : ite
    )
    ?.filter((ite) => {
      if (
        !hasValidM500Data &&
        (ite.dataIndex === "headAngle" ||
          ite.dataIndex === "arcImpedance" ||
          ite.dataIndex === "pipe")
      ) {
        return null;
      }
      return ite;
    })
    ?.filter((ite) => ite != null)
    ?.map((column) => {
      return {
        ...column,
        headerName: showRangeColumns?.includes(column.field)
          ? column.headerName +
            " " +
            getRangeColumn({
              avgTlogItem: tlogData?.[0],
              column: tlogHelper.mappingColumnTableLimit[column.field],
              tableLimitData: tableLimitData,
              type: type,
            })?.showColor
          : column.headerName,
      };
    });

  return (
    <div
      className="ag-theme-alpine tlog-grid-container"
      style={{ height: 500, width: "100%" }}
    >
      <EnterpriseGridCommon
        isLoading={loading}
        rows={rows}
        columnDefs={columnsFormatted}
        exportFileName={"tlog"}
      />
    </div>
  );
};

export default TlogGrid;
