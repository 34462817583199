const FETCH_TILTVIEW_SAGA = "@@tiltview/fetchTiltViewSaga";
const SET_TILTVIEW_LIST = "@@tiltview/setTiltviewList";
const TILTVIEW_LOADER_START = "@@tiltview/startLoader";
const TILTVIEW_LOADER_STOP = "@@tiltview/stopLoader";

export {
  FETCH_TILTVIEW_SAGA,
  SET_TILTVIEW_LIST,
  TILTVIEW_LOADER_START,
  TILTVIEW_LOADER_STOP,
};
