import { notification } from "antd";
import { put, call, all, takeLatest } from "redux-saga/effects";
import {
  setZoneviewList,
  startZoneviewLoader,
  stopZoneviewLoader,
} from "./actions";
import { FETCH_ZONEVIEW_SAGA } from "./type";
import { BASE_URL } from "../../config";
import { passViewHelper } from "../../helpers/passzone/pass-zone-helper";
import fetch from "../../services/fetch";

function* fetchZoneViewSaga(action) {
  try {
    yield put(startZoneviewLoader());

    const { project_id, weld_number, project_name } = action.payload;

    const url = new URL(`${BASE_URL}/v1/exposed/log-data-zone-view`);
    if (project_id) url.searchParams.append("project_id", project_id);
    if (weld_number) url.searchParams.append("weld_number", weld_number);
    if (project_name) url.searchParams.append("project_name", project_name);

    const res = yield fetch(url.toString(), {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    });

    let allSlogs = [];
    let allAvgTlogs = [];

    const limit = 10000;
    let skip = 0;

    let slogLoopContinue = true;

    do {
      const slogs = new URL(`${BASE_URL}/v1/exposed/all-slogs`);

      if (project_id) slogs.searchParams.append("project_id", project_id);
      if (project_name) slogs.searchParams.append("project_name", project_name);
      if (weld_number) slogs.searchParams.append("weld_number", weld_number);

      slogs.searchParams.append(
        "projectionParams",
        passViewHelper.projectionKeysPassZoneSlog.join(",")
      );

      slogs.searchParams.append("skip", skip);
      slogs.searchParams.append("limit", limit);

      const res = yield fetch(slogs.toString(), {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });

      if (res.status === 403) {
        throw new Error("Unauthorized");
      }

      const slogResponse = yield res.json();

      console.log(slogResponse, "slogResponse");

      skip += limit;

      slogLoopContinue =
        slogResponse?.data?.recordList?.recordList?.length == limit;

      // eslint-disable-next-line no-unsafe-optional-chaining
      allSlogs = [...allSlogs, ...slogResponse?.data?.recordList?.recordList];
    } while (slogLoopContinue);

    const avgTlogLimit = 100000;
    let skipAvgTlog = 0;

    let avgLogLoopContinue = true;

    do {
      const avgTlog = new URL(`${BASE_URL}/v1/exposed/avg-tlog`);

      if (project_id) avgTlog.searchParams.append("project_id", project_id);
      if (project_name)
        avgTlog.searchParams.append("project_name", project_name);
      if (weld_number) avgTlog.searchParams.append("weld_number", weld_number);

      avgTlog.searchParams.append("skip", skipAvgTlog);
      avgTlog.searchParams.append("limit", avgTlogLimit);

      avgTlog.searchParams.append(
        "projectionParams",
        passViewHelper.projectionKeysAvgTlog.join(",")
      );

      const resAvg = yield fetch(avgTlog.toString(), {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });

      if (resAvg.status === 403) {
        throw new Error("Unauthorized");
      }

      const avgTlogResponse = yield resAvg.json();

      avgLogLoopContinue =
        avgTlogResponse?.data?.recordList?.recordList?.length == avgTlogLimit;

      skipAvgTlog += avgTlogLimit;

      // Concatenate new records
      allAvgTlogs = [
        ...allAvgTlogs,
        ...avgTlogResponse?.data?.recordList?.recordList,
      ];
    } while (avgLogLoopContinue);

    if (res.status === 403) {
      throw new Error("Unauthorized");
    }

    const response = yield res.json();

    const responseState = {
      avgTlogList: {
        count: 0,
        recordList: allAvgTlogs,
      },
      allSlogs: {
        count: 0,
        recordList: allSlogs,
      },
      tableLimitData: response?.data?.tableLimitData,
      weldParams: response?.data?.weldParams,
    };

    switch (response.status) {
      case 200:
        yield put(setZoneviewList(responseState));
        break;
      case 410:
        notification.warn({
          message: response?.message,
          duration: 6,
        });
        break;
      default:
        notification.error({
          message: "Something went wrong.",
        });
    }

    yield put(stopZoneviewLoader());
  } catch (error) {
    console.log(error, "Failed to fetch slogs");
    notification.error({
      message: error.message || "Failed to fetch slogs.",
    });
    yield put(stopZoneviewLoader());
  }
}
function* zoneviewWatcher() {
  yield takeLatest(FETCH_ZONEVIEW_SAGA, fetchZoneViewSaga);
}

function* zoneviewSagas() {
  yield all([call(zoneviewWatcher)]);
}

export default zoneviewSagas;
