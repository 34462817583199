import React, { useState } from "react";
import EnterpriseGridCommon from "./Ag-Grid";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useTranslation } from "react-i18next";
import ExclamationIcon from "../../assets/exclamation.svg";
import { logDataHelperFunctions } from "../../helpers/logdata-helper";
import { zoneViewHelper } from "../../helpers/zone-view/zone-view-helper";
import { aggridHelpers } from "../../helpers/common/ag-grid-helper";

const TiltViewGrid = ({ rows, reportColumns, isLoading, handleRowClick }) => {
  const { t } = useTranslation();

  const valueRender = (params) => {
    const text = params.value;
    if (text?.includes("-color")) {
      return <span className="red-note">{text?.replace("-color", "")}</span>;
    }
    return isNaN(text) ? "" : text;
  };

  const [columnDefs] = useState([
    {
      headerName: t("slogColumntitle.sl.no"),
      field: "sl_no",
      key: "sl_no",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: false,
    },
    {
      headerName: t("slogColumntitle.status"),
      field: "status",
      key: "status",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: false,
      cellRenderer: (params) => {
        return params.value
          ? logDataHelperFunctions.getIconForStatus(params.value)
          : "";
      },
    },
    {
      headerName: t("slogColumntitle.stationName"),
      field: "stationNumbers",
      key: "stationNumbers",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: true,
    },
    {
      headerName: t("slogColumntitle.buyType"),
      field: "buyType",
      key: "buyType",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: true,
    },
    {
      headerName: t("slogColumntitle.torch"),
      field: "type",
      key: "type",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: true,
    },
    {
      headerName: t("slogColumntitle.pass"),
      field: "name",
      key: "pass",
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: true,
      cellRenderer: (params) => {
        return params.value == "ex" ? (
          <img src={ExclamationIcon} alt="Exclamation" />
        ) : (
          params.value
        );
      },
    },
    {
      headerName: t("slogColumntitle.tiltRange"),
      field: "tiltRange",
      key: "tiltRange",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: true,
    },
    {
      headerName: t("slogColumntitle.weldstartTime"),
      field: "weldStartTimeRaw",
      key: "weldStartTime",
      editable: true,
      pivot: true,
      cellRenderer: (params) => {
        return Number(params.value) > 0
          ? logDataHelperFunctions.formatWeldStartTime(params.value, true)
          : "-";
      },
      filter: "agDateColumnFilter",
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          return aggridHelpers.dateFilter(filterLocalDateAtMidnight, cellValue);
        },
        maxNumConditions: 1,
        inRangeInclusive: true,
      },
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: false,
      minWidth: 150,
    },
    {
      headerName: t("slogColumntitle.weldTime"),
      field: "weld_time",
      key: "weld_time",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: false,
    },
    {
      headerName: t("slogColumntitle.autoIncrement"),
      field: "autoIncrement",
      key: "autoIncrement",
      editable: true,
      pivot: true,
      pivotComparator: (a, b) => b.localeCompare(a),
      enableRowGroup: false,
      minWidth: 150,
    },
    {
      headerName: t("slogColumntitle.current"),
      key: "currentA",
      children: [
        {
          headerName: t("slogColumntitle.low"),
          field: "current_low",
          key: "currentLow",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.avg"),
          field: "current_avg",
          key: "currentAvg",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.high"),
          field: "current_high",
          key: "currentHigh",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
      ],
    },
    {
      headerName: t("slogColumntitle.volt"),
      key: "voltV",
      children: [
        {
          headerName: t("slogColumntitle.low"),
          field: "volt_low",
          key: "voltLow",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.avg"),
          field: "volt_avg",
          key: "voltAvg",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.high"),
          field: "volt_high",
          key: "voltHigh",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
      ],
    },
    {
      headerName: t("slogColumntitle.travelSpeed"),
      key: "travelSpeed",
      children: [
        {
          headerName: t("slogColumntitle.low"),
          field: "travel_speed_low",
          key: "travelLow",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.avg"),
          field: "travel_speed_avg",
          key: "travelAvg",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.high"),
          field: "travel_speed_high",
          key: "travelHigh",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
      ],
    },
    {
      headerName: t("slogColumntitle.trueEnergy"),
      key: "trueEnergy",
      children: [
        {
          headerName: t("slogColumntitle.low"),
          field: "true_energy_low",
          key: "travelLow",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.avg"),
          field: "true_energy_avg",
          key: "travelAvg",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.high"),
          field: "true_energy_high",
          key: "travelHigh",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
      ],
    },
    {
      headerName: t("slogColumntitle.heat"),
      key: "heat",
      children: [
        {
          headerName: t("slogColumntitle.low"),
          field: "heat_low",
          key: "travelLow",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.avg"),
          field: "heat_avg",
          key: "travelAvg",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.high"),
          field: "heat_high",
          key: "travelHigh",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
      ],
    },
    {
      headerName: t("slogColumntitle.wireSpeed"),
      key: "wireSpeed",
      children: [
        {
          headerName: t("slogColumntitle.low"),
          field: "wire_speed_low",
          key: "travelLow",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.avg"),
          field: "wire_speed_avg",
          key: "travelAvg",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.high"),
          field: "wire_speed_high",
          key: "travelHigh",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
      ],
    },
    {
      headerName: t("slogColumntitle.oscillationWidth"),
      key: "oscillationWidth",
      children: [
        {
          headerName: t("slogColumntitle.low"),
          field: "oscillation_width_low",
          key: "travelLow",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.avg"),
          field: "oscillation_width_avg",
          key: "travelAvg",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.high"),
          field: "oscillation_width_high",
          key: "travelHigh",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
      ],
    },
    {
      headerName: t("slogColumntitle.frequency"),
      key: "frequency",
      children: [
        {
          headerName: t("slogColumntitle.low"),
          field: "frequency_low",
          key: "travelLow",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.avg"),
          field: "frequency_avg",
          key: "travelAvg",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.high"),
          field: "frequency_high",
          key: "travelHigh",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
      ],
    },
    {
      headerName: t("slogColumntitle.target"),
      key: "target",
      children: [
        {
          headerName: t("slogColumntitle.low"),
          field: "target_low",
          key: "travelLow",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.avg"),
          field: "target_avg",
          key: "travelAvg",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.high"),
          field: "target_high",
          key: "travelHigh",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
      ],
    },
    {
      headerName: t("slogColumntitle.horizontalBias"),
      key: "horizontalBias",
      children: [
        {
          headerName: t("slogColumntitle.low"),
          field: "horizontal_bias_low",
          key: "travelLow",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.avg"),
          field: "horizontal_bias_avg",
          key: "travelAvg",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.high"),
          field: "horizontal_bias_high",
          key: "travelHigh",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
      ],
    },
    {
      headerName: t("slogColumntitle.totalWireConsumed"),
      key: "totalWireConsumed",
      children: [
        {
          headerName: t("slogColumntitle.low"),
          field: "total_wire_consumed_low",
          key: "travelLow",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.avg"),
          field: "total_wire_consumed_avg",
          key: "travelAvg",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.high"),
          field: "total_wire_consumed_high",
          key: "travelHigh",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
      ],
    },
    {
      headerName: t("slogColumntitle.arcImpedance"),
      key: "arcImpedance",
      children: [
        {
          headerName: t("slogColumntitle.low"),
          field: "arcImpedance_low",
          key: "arcImpedance_low",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.avg"),
          field: "arcImpedance_avg",
          key: "arcImpedance_avg",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
        {
          headerName: t("slogColumntitle.high"),
          field: "arcImpedance_high",
          key: "arcImpedance_high",
          editable: true,
          pivot: true,
          pivotComparator: (a, b) => b.localeCompare(a),
          enableRowGroup: false,
          cellRenderer: (params) => {
            return valueRender(params);
          },
        },
      ],
    },
  ]);

  const onRowClicked = (params) => {
    console.log("Row clicked", params);
    handleRowClick(params.data);
  };

  return (
    <div className="ag-theme-alpine" style={{ height: 500, width: "100%" }}>
      <EnterpriseGridCommon
        rows={rows}
        columnDefs={zoneViewHelper.mapZoneViewColumnTitles(
          columnDefs,
          reportColumns
        )}
        isLoading={isLoading}
        onRowClicked={onRowClicked}
        exportFileName={"tilt-view"}
      />
    </div>
  );
};

export default TiltViewGrid;
