import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { logDataHelperFunctions } from "../../helpers/logdata-helper";
import { startTiltviewLoader } from "../../redux/TiltView/actions";
import { FETCH_TILTVIEW_SAGA } from "../../redux/TiltView/type";
import TiltViewGrid from "../AgGrid/Tilt-View";
import CommonLoader from "../Loaders/loader";

const TiltView = ({ weld_number }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedProjectId = useSelector(
    (state) => state?.projectReducer?.selectedProject?.project_id
  );
  const selectedProjectName = useSelector(
    (state) => state?.projectReducer?.selectedProject?.project_name
  );

  const { tiltviewList, isTiewviewLoding } = useSelector(
    (state) => state?.tiltviewReducer
  );

  const { projectReducer } = useSelector((state) => state);

  const [reportColumns, setReportColumns] = useState(
    logDataHelperFunctions.sampleReportColumns
  );

  useEffect(() => {
    if (projectReducer?.selectedProject?.reportColumns) {
      try {
        let parsedData = JSON.parse(
          projectReducer?.selectedProject?.reportColumns
        );
        setReportColumns(parsedData);
      } catch (e) {
        console.log(e);
      }
    }
  }, [projectReducer?.selectedProject?.reportColumns]);

  useEffect(() => {
    const params = {
      project_id: selectedProjectId,
      project_name: selectedProjectName,
      weld_number: weld_number,
    };
    dispatch(startTiltviewLoader());
    dispatch({ type: FETCH_TILTVIEW_SAGA, payload: params });
  }, [dispatch, selectedProjectId, selectedProjectName, weld_number]);

  const transformData = (data) => {
    return data?.map((item, index) => ({
      key: index,
      sl_no: index + 1,
      status: item.status,
      stationNumbers: item.stationName,
      buyType: item.bugType,
      type: item.torch,
      name: item.pass,
      tiltRange: item.tiltRange,
      // weldStartTime: item.weldStartTime,
      weldStartTime: logDataHelperFunctions.formatWeldStartTime(
        item?.unFormattedWeldStartTime,
        true
      ),
      weldStartTimeRaw: item?.unFormattedWeldStartTime,
      weld_time: item.weldTime,
      autoIncrement: item.autoIncrement || "No",
      current_low: item.current?.low,
      current_avg: item.current?.avg,
      current_high: item.current?.high,
      volt_low: item.volt?.low,
      volt_avg: item.volt?.avg,
      volt_high: item.volt?.high,
      travel_speed_low: item.travelSpeed?.low,
      travel_speed_avg: item.travelSpeed?.avg,
      travel_speed_high: item.travelSpeed?.high,
      true_energy_low: item.trueEnergy?.low,
      true_energy_avg: item.trueEnergy?.avg,
      true_energy_high: item.trueEnergy?.high,
      heat_low: item.heat?.low,
      heat_avg: item.heat?.avg,
      heat_high: item.heat?.high,
      wire_speed_low: item.wireSpeed?.low,
      wire_speed_avg: item.wireSpeed?.avg,
      wire_speed_high: item.wireSpeed?.high,
      oscillation_width_low: item.oscillationWidth?.low,
      oscillation_width_avg: item.oscillationWidth?.avg,
      oscillation_width_high: item.oscillationWidth?.high,
      frequency_low: item.frequency?.low,
      frequency_avg: item.frequency?.avg,
      frequency_high: item.frequency?.high,
      target_low: item.target?.low,
      target_avg: item.target?.avg,
      target_high: item.target?.high,
      horizontal_bias_low: item.horizontalBias?.low,
      horizontal_bias_avg: item.horizontalBias?.avg,
      horizontal_bias_high: item.horizontalBias?.high,
      total_wire_consumed_low: item.totalWireConsumed?.low,
      total_wire_consumed_avg: item.totalWireConsumed?.avg,
      total_wire_consumed_high: item.totalWireConsumed?.high,
      arcImpedance_low: item.arcImpedance?.low || "",
      arcImpedance_avg: item.arcImpedance?.avg || "",
      arcImpedance_high: item.arcImpedance?.high || "",
      additionalParams: {
        stationNumbers: item?.stationName,
        station_numbe: item?.stationName,
        buyType: item?.bugType,
        type: item?.torch,
        zone: "",
        s_deviceid_event: item?.additionalParams?.s_deviceid_event,
        weld_time: item?.weldTime,
        start_time: item?.unFormattedWeldStartTime,
        end_time: item?.additionalParams?.end_time,
        band_diameter: item?.additionalParams?.band_diameter,
        logging_distance: item?.additionalParams?.logging_distance,
        bug_number: item?.additionalParams?.bug_number,
        lead_wire_diameter: item?.additionalParams?.lead_wire_diameter,
        trail_wire_diameter: item?.additionalParams?.trail_wire_diameter,
        pipe_diameter: item?.additionalParams?.pipe_diameter,
        autoIncrement: item?.autoIncrement,
        gps: item?.additionalParams?.gps,
        iwm: item?.additionalParams?.iwm,
        tilt_start: item?.additionalParams?.tilt_start,
        tilt_end: item?.additionalParams?.tilt_end,
        isM500: item?.additionalParams?.isM500,
      },
    }));
  };

  const handleRowClick = (record) => {
    const {
      stationNumbers = "",
      station_number = "",
      buyType = "",
      type = "",
      zone = "",
      s_deviceid_event = "",
      weld_time = "",
      start_time = "",
      end_time = "",
      band_diameter = "",
      logging_distance = "",
      bug_number = "",
      lead_wire_diameter = "",
      trail_wire_diameter = "",
      pipe_diameter = "",
      autoIncrement = "",
      gps = "",
      iwm = "",
      tilt_start = "",
      tilt_end = "",
      // eslint-disable-next-line no-unsafe-optional-chaining
      isM500 = false,
    } = record?.additionalParams;

    history.push("/tlog-data", {
      stationNumbers,
      station_number,
      buyType,
      type,
      zone,
      weld_number,
      s_deviceid_event: s_deviceid_event,
      weld_time,
      start_time,
      end_time,
      band_diameter,
      logging_distance,
      bug_number,
      lead_wire_diameter,
      trail_wire_diameter,
      pipe_diameter,
      autoIncrement,
      gps,
      iwm,
      tilt_start,
      tilt_end,
      isM500,
    });
  };

  return (
    <div className="w-full">
      {isTiewviewLoding ? (
        <div className="slogdata-loader">
          <CommonLoader />
        </div>
      ) : tiltviewList.length > 0 ? (
        <TiltViewGrid
          rows={transformData(tiltviewList)}
          reportColumns={reportColumns}
          isLoading={isTiewviewLoding}
          handleRowClick={handleRowClick}
        />
      ) : (
        <div className="slog-NoData">
          <p>{t("sLogData.noDataAvailable")}</p>
        </div>
      )}
    </div>
  );
};

export default TiltView;
