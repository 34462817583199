import { LeftOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import SlogTab from "./Tab";
import { startZoneviewLoader } from "../../redux/zoneView/actions";
import { FETCH_ZONEVIEW_SAGA } from "../../redux/zoneView/type";

const SlogWeld = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { weld_number } = useParams();
  const selectedProjectId = useSelector(
    (state) => state?.projectReducer?.selectedProject?.project_id
  );
  const selectedProjectName = useSelector(
    (state) => state?.projectReducer?.selectedProject?.project_name
  );

  useEffect(() => {
    const params = {
      project_id: selectedProjectId,
      project_name: selectedProjectName,
      weld_number: weld_number,
    };
    dispatch(startZoneviewLoader());
    dispatch({ type: FETCH_ZONEVIEW_SAGA, payload: params });
  }, [dispatch]);

  const [, setActiveTab] = useState("1");

  return (
    <>
      <div className="slogweld-title">
        <LeftOutlined className="leftoutlined" />
        <Link to={"/data360-home"}>
          <h2 className="slogweld-header-active">
            {t("slogColumntitle.logData")}
          </h2>
        </Link>
        <LeftOutlined className="leftoutlined" />
        <h2 className="slogweld-header">
          {t("slogColumntitle.weld")} {weld_number}
        </h2>
      </div>
      <SlogTab setActiveTab={setActiveTab} weld_number={weld_number} />
    </>
  );
};

export default SlogWeld;
