import {
  SET_TILTVIEW_LIST,
  TILTVIEW_LOADER_START,
  TILTVIEW_LOADER_STOP,
} from "./type";

const startTiltviewLoader = () => ({
  type: TILTVIEW_LOADER_START,
});

const stopTiltviewLoader = () => ({
  type: TILTVIEW_LOADER_STOP,
});

const setTiltviewList = (payload) => ({
  type: SET_TILTVIEW_LIST,
  payload,
});

export { startTiltviewLoader, stopTiltviewLoader, setTiltviewList };
