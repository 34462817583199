import {
  convertValue,
  getDecimalPoint,
  MEASUREMENT_KEYS,
  reportColumnEnums,
} from "../unit-conversion";

// let tlogInput = {
//   sl_no: 13,
//   status: "-",
//   event: "777777777777777777777777_259221",
//   time: "19:37:10",
//   tilt: "27",
//   pass: "-",
//   zone: "CS",
//   distance: "9.828",
//   travelSpeed: "23.0",
//   volts: "25.02",
//   current: "337.4",
//   wireSpeed: "330.1",
//   oscillationWidth: "0.13",
//   target: "25",
//   horizontalBias: "0",
//   frequency: "200",
//   totalWireConsumed: "0.000",
//   trueEnergy: "22.0",
//   heat: "22.02",
// };

const mapTlogWithUnitConversion = (tlogInput, reportColumns) => {
  //   let reportColumns = {
  //     switch: "All",
  //     params: ["Arc Current", "Arc Volts", "Travel Speed", "Wire Speed", "Heat"],
  //     units: {
  //       ArcCurrent: "A",
  //       ArcVolts: "V",
  //       "Travel Speed": "in/min",
  //       "True Energy": "kJ/in",
  //       Heat: "kJ/in",
  //       "Wire Speed": "in/min",
  //       "Oscillation Width": "in",
  //       Frequency: "cyc/min",
  //       Target: "A",
  //       "Horizontal Bias": "A",
  //       "Total Wire Consumed": "in",
  //       "Band Diameter": "in",
  //       "Wire Diameter": "in",
  //       "Logging Distance": "in",
  //       Distance: "in",
  //     },
  //   };

  tlogInput.distance = convertValue(
    tlogInput.distance,
    reportColumns?.units?.["Logging Distance"],
    false,
    getDecimalPoint(MEASUREMENT_KEYS.DISTANCE)
  );
  tlogInput.travelSpeed = convertValue(
    tlogInput.travelSpeed,
    reportColumns?.units?.["Travel Speed"],
    false,
    getDecimalPoint(MEASUREMENT_KEYS.TRAVEL_SPEED)
  );
  tlogInput.volts = convertValue(
    tlogInput.volts,
    reportColumns?.units?.ArcVolts,
    false,
    getDecimalPoint(MEASUREMENT_KEYS.VOLTS)
  );
  tlogInput.current = convertValue(
    tlogInput.current,
    reportColumns?.units?.ArcCurrent,
    false,
    getDecimalPoint(MEASUREMENT_KEYS.AMPS)
  );
  tlogInput.wireSpeed = convertValue(
    tlogInput.wireSpeed,
    reportColumns?.units?.["Wire Speed"],
    false,
    getDecimalPoint(MEASUREMENT_KEYS.WIRE_SPEED)
  );
  tlogInput.oscillationWidth = convertValue(
    tlogInput.oscillationWidth,
    reportColumns?.units?.["Oscillation Width"],
    false,
    getDecimalPoint(MEASUREMENT_KEYS.OSCILLATE_WIDTH)
  );

  tlogInput.target = convertValue(
    tlogInput.target,
    reportColumns?.units?.Target,
    false,
    getDecimalPoint(MEASUREMENT_KEYS.TARGET)
  );

  tlogInput.horizontalBias = convertValue(
    tlogInput.horizontalBias,
    reportColumns?.units?.["Horizontal Bias"],
    false,
    getDecimalPoint(MEASUREMENT_KEYS.HORIZONTAL_BIAS)
  );

  tlogInput.frequency = convertValue(
    tlogInput.frequency,
    reportColumns?.units?.Frequency,
    false,
    getDecimalPoint(MEASUREMENT_KEYS.FREQUENCY)
  );

  tlogInput.totalWireConsumed = convertValue(
    tlogInput.totalWireConsumed,
    reportColumns?.units?.["Total Wire Consumed"],
    false,
    getDecimalPoint(MEASUREMENT_KEYS.TOTAL_WIRE_CONSUMED)
  );

  tlogInput.trueEnergy = convertValue(
    tlogInput.trueEnergy,
    reportColumns?.units?.["True Energy"],
    false,
    getDecimalPoint(MEASUREMENT_KEYS.TRUE_ENERGY)
  );

  tlogInput.heat = convertValue(
    tlogInput.heat,
    reportColumns?.units?.Heat,
    false,
    getDecimalPoint(MEASUREMENT_KEYS.HEAT)
  );

  return tlogInput;
};

const mappingColumnTableLimit = {
  volts: "volts",
  current: "current",
  wireSpeed: "wire_speed",
  oscillationWidth: "oscillation_width",
  trueEnergy: "heat",
  heat: "calculated_heat",
  travelSpeed: "travel_speed",
};

const rangeCalculation = (value, column, reportColumns, isFromPQRTab) => {
  let reverseMappingToReportColumns = {
    volts: "ArcVolts",
    current: "ArcCurrent",
    wire_speed: "Wire Speed",
    oscillation_width: "Oscillation Width",
    heat: "True Energy",
    calculated_heat: "Heat",
    travel_speed: "Travel Speed",
  };

  const getDecimalPointsValue = (columnValue) => {
    switch (columnValue) {
      case reverseMappingToReportColumns.volts:
        return getDecimalPoint(MEASUREMENT_KEYS.VOLTS);
      case reverseMappingToReportColumns.current:
        return getDecimalPoint(MEASUREMENT_KEYS.CURRENT_A);
      case reverseMappingToReportColumns.wire_speed:
        return getDecimalPoint(MEASUREMENT_KEYS.WIRE_SPEED);
      case reverseMappingToReportColumns.oscillation_width:
        return getDecimalPoint(MEASUREMENT_KEYS.OSCILLATE_WIDTH);
      case reverseMappingToReportColumns.heat:
        return getDecimalPoint(MEASUREMENT_KEYS.TRUE_ENERGY);
      case reverseMappingToReportColumns.calculated_heat:
        return getDecimalPoint(MEASUREMENT_KEYS.HEAT);
      case reverseMappingToReportColumns.travel_speed:
        return getDecimalPoint(MEASUREMENT_KEYS.TRAVEL_SPEED);
    }
  };

  return convertValue(
    value,
    isFromPQRTab
      ? ""
      : reportColumns?.units?.[reverseMappingToReportColumns?.[column]] || "",
    false,
    getDecimalPointsValue(reverseMappingToReportColumns?.[column])
  );
};

const returnUnitBasedOnColumns = (column, reportColumns) => {
  console.log(reportColumns, "reportColumns - returnUnitBasedOnColumns");
  switch (column) {
    case reportColumnEnums.LoggingDistance:
    case reportColumnEnums.WireDiameter:
    case reportColumnEnums.Distance:
    case reportColumnEnums.BandDiameter: {
      return reportColumns?.units?.[column] || "in";
    }
  }
};

const mapTlogColumns = (
  inputColumns,
  reportColumns,
  hasData,
  isM500 = false
) => {
  const columnsMapping = {
    CURRENT_A: "current",
    VOLT_V: "volt",
    TRAVEL_SPEED: "travelSpeed",
    TRUE_ENERGY: "trueEnergy",
    DISTANCE: "distance",
    HEAT: "heat",
    WIRE_SPEED: "wireSpeed",
    OSCILLATION_WIDTH: "oscillationWidth",
    FREQUENCY: "frequency",
    TARGET: "target",
    HORIZONTAL_BIAS: "horizontalBias",
    TOTAL_WIRE_CONSUMED: "totalWireConsumed",
    HEAD_ANGLE: "headAngle",
    ARC_IMPEDANCE: "arcImpedance",
    PIPE: "pipe",
  };

  return inputColumns
    ?.map((ite) => {
      let changeKeys = [
        columnsMapping.CURRENT_A,
        columnsMapping.VOLT_V,
        columnsMapping.TRAVEL_SPEED,
        columnsMapping.DISTANCE,
        columnsMapping.TRUE_ENERGY,
        columnsMapping.HEAT,
        columnsMapping.WIRE_SPEED,
        columnsMapping.OSCILLATION_WIDTH,
        columnsMapping.FREQUENCY,
        columnsMapping.TARGET,
        columnsMapping.HORIZONTAL_BIAS,
        columnsMapping.TOTAL_WIRE_CONSUMED,
        columnsMapping.HEAD_ANGLE,
        columnsMapping.ARC_IMPEDANCE,
      ];

      const m500Columns = [
        columnsMapping.HEAD_ANGLE,
        columnsMapping.ARC_IMPEDANCE,
        columnsMapping.PIPE,
      ];

      if (changeKeys.includes(ite.key)) {
        if (!isM500 && m500Columns.includes(ite.key)) {
          return null;
        }
        switch (ite.key) {
          case columnsMapping.CURRENT_A: {
            return {
              ...ite,
              title: `Current (${
                hasData ? "A" : reportColumns?.units?.ArcCurrent || "A"
              })`,
              headerName: `Current (${
                hasData ? "A" : reportColumns?.units?.ArcCurrent || "A"
              })`,
            };
          }
          case columnsMapping.VOLT_V: {
            return {
              ...ite,
              title: `Volt (${
                hasData ? "V" : reportColumns?.units?.ArcVolts || "V"
              })`,
              headerName: `Volt (${
                hasData ? "V" : reportColumns?.units?.ArcVolts || "V"
              })`,
            };
          }

          case columnsMapping.TRAVEL_SPEED: {
            return {
              ...ite,
              title: `Travel Speed (${
                hasData
                  ? "in/min"
                  : reportColumns?.units?.["Travel Speed"] || "in/min"
              })`,
              headerName: `Travel Speed (${
                hasData
                  ? "in/min"
                  : reportColumns?.units?.["Travel Speed"] || "in/min"
              })`,
            };
          }

          case columnsMapping.TRUE_ENERGY: {
            return {
              ...ite,
              title: `True Energy (${
                hasData
                  ? "KJ/in"
                  : reportColumns?.units?.["True Energy"] || "KJ/in"
              })`,
              headerName: `True Energy (${
                hasData
                  ? "KJ/in"
                  : reportColumns?.units?.["True Energy"] || "KJ/in"
              })`,
            };
          }

          case columnsMapping.HEAT: {
            return {
              ...ite,
              title: `Heat (${
                hasData ? "KJ/in" : reportColumns?.units?.Heat || "KJ/in"
              })`,
              headerName: `Heat (${
                hasData ? "KJ/in" : reportColumns?.units?.Heat || "KJ/in"
              })`,
            };
          }

          case columnsMapping.WIRE_SPEED: {
            return {
              ...ite,
              title: `Wire Speed (${
                hasData
                  ? "in/min"
                  : reportColumns?.units?.["Wire Speed"] || "in/min"
              })`,
              headerName: `Wire Speed (${
                hasData
                  ? "in/min"
                  : reportColumns?.units?.["Wire Speed"] || "in/min"
              })`,
            };
          }

          case columnsMapping.OSCILLATION_WIDTH: {
            return {
              ...ite,
              title: `Oscillation Width (${
                hasData
                  ? "in"
                  : reportColumns?.units?.["Oscillation Width"] || "in"
              })`,
              headerName: `Oscillation Width (${
                hasData
                  ? "in"
                  : reportColumns?.units?.["Oscillation Width"] || "in"
              })`,
            };
          }

          case columnsMapping.FREQUENCY: {
            return {
              ...ite,
              title: `Frequency (${
                hasData
                  ? "cyc/min"
                  : reportColumns?.units?.Frequency || "cyc/min"
              })`,
              headerName: `Frequency (${
                hasData
                  ? "cyc/min"
                  : reportColumns?.units?.Frequency || "cyc/min"
              })`,
            };
          }

          case columnsMapping.TARGET: {
            return {
              ...ite,
              title: `Target (${
                hasData ? "A" : reportColumns?.units?.Target || "A"
              })`,
              headerName: `Target (${
                hasData ? "A" : reportColumns?.units?.Target || "A"
              })`,
            };
          }

          case columnsMapping.HORIZONTAL_BIAS: {
            return {
              ...ite,
              title: `Horizontal Bias (${
                hasData ? "A" : reportColumns?.units?.["Horizontal Bias"] || "A"
              })`,
              headerName: `Horizontal Bias (${
                hasData ? "A" : reportColumns?.units?.["Horizontal Bias"] || "A"
              })`,
            };
          }

          case columnsMapping.TOTAL_WIRE_CONSUMED: {
            return {
              ...ite,
              title: `Total Wire Consumed (${
                hasData
                  ? "in"
                  : reportColumns?.units?.["Total Wire Consumed"] || "in"
              })`,
              headerName: `Total Wire Consumed (${
                hasData
                  ? "in"
                  : reportColumns?.units?.["Total Wire Consumed"] || "in"
              })`,
            };
          }

          case columnsMapping.DISTANCE: {
            return {
              ...ite,
              title: `Distance (${
                hasData
                  ? "in"
                  : reportColumns?.units?.["Logging Distance"] || "in"
              })`,
              headerName: `Distance (${
                hasData
                  ? "in"
                  : reportColumns?.units?.["Logging Distance"] || "in"
              })`,
            };
          }
          case columnsMapping.HEAD_ANGLE: {
            return {
              ...ite,
              title: `Head Angle (${
                hasData ? "deg" : reportColumns?.units?.["Head Angle"] || "deg"
              })`,
              headerName: `Head Angle (${
                hasData ? "deg" : reportColumns?.units?.["Head Angle"] || "deg"
              })`,
            };
          }
          case columnsMapping.ARC_IMPEDANCE: {
            // Arc Impedance has only one unit (ohms), no variations.
            return {
              ...ite,
              title: `Arc Impedance (${
                hasData
                  ? "ohms"
                  : reportColumns?.units?.["Arc Impedance"] || "ohms"
              })`,
              headerName: `Arc Impedance (${
                hasData
                  ? "ohms"
                  : reportColumns?.units?.["Arc Impedance"] || "ohms"
              })`,
            };
          }
        }
      } else {
        if (!isM500 && m500Columns.includes(ite.key)) {
          return null;
        }
        return ite;
      }
    })
    .filter((ite) => ite);
};

export const tlogHelper = {
  mapTlogWithUnitConversion,
  mapTlogColumns,
  mappingColumnTableLimit,
  rangeCalculation,
  returnUnitBasedOnColumns,
};
