import { all } from "redux-saga/effects";

import auth from "./auth";
import clientSagas from "./client";
import tiltviewSagas from "../redux/TiltView/saga";
import pqrdataSagas from "../redux/pqrReportData/saga";
import projectSaga from "../redux/project/saga";
import slogsReportSagas from "../redux/reportGenerator/saga";
import slogsSaga from "../redux/sLogdata/saga";
import statusSagas from "../redux/statusCalculation/saga";
import tlogsSaga from "../redux/tLogData/saga";
import zoneviewSaga from "../redux/zoneView/saga";

export default function* rootSaga() {
  yield all([
    clientSagas(),
    auth(),
    projectSaga(),
    slogsSaga(),
    zoneviewSaga(),
    tlogsSaga(),
    slogsReportSagas(),
    statusSagas(),
    pqrdataSagas(),
    tiltviewSagas(),
  ]);
}
